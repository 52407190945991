import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialOutletTroubleshootState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    totalCountData: 0,
    entities: null,
    entitiesData: null,
    dashboardItemEntities: null,
    dashboardProgressStatusEntities: null,
    dashboardOutletBranchEntities: null,
    dashboardOutletPICEntities: null,
    dashboardHandledByEntities: null,
    dashboardDurationsEntities: null,
    dashboardLastTenEntities: null,
    dailyActivityDataByTaskIDTaskItemID: undefined,
    outletTroubleshootDataByID: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true,
    batchResult: [],
    initialFilter: {
        filter: {
            user_id: "",
            task_category_id: "88325",
            task_item_id: "",
            daterange: moment.utc(moment().startOf('month')).format('YYYY-MM-DD HH:mm:ss') + '[-]' + moment.utc(moment().endOf('month')).format('YYYY-MM-DD HH:mm:ss'),
            daterange_label: moment.utc(moment().endOf('year')).format('YYYY'),
            start: moment().startOf('month'),
            end: moment().endOf('month'),
            status_id: "",
            search: ""
        },
        sortOrder: "desc",
        sortField: "task_date",
        pageNumber: 1,
        pageSize: 10
    }
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const OutletTroubleshootReducers = createSlice({
    name: 'outlet_troubleshoot',
    initialState: initialOutletTroubleshootState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getDashboard
        dashboardItemsFetched: (state, action) => {
            state.listLoading = false
            state.dashboardItemEntities = action.payload.dashboardItemEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardProgressStatusFetched: (state, action) => {
            state.listLoading = false
            state.dashboardProgressStatusEntities = action.payload.dashboardProgressStatusEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardOutletBranchFetched: (state, action) => {
            state.listLoading = false
            state.dashboardOutletBranchEntities = action.payload.dashboardOutletBranchEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardOutletPICFetched: (state, action) => {
            state.listLoading = false
            state.dashboardOutletPICEntities = action.payload.dashboardOutletPICEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardHandledByFetched: (state, action) => {
            state.listLoading = false
            state.dashboardHandledByEntities = action.payload.dashboardHandledByEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardDurationsFetched: (state, action) => {
            state.listLoading = false
            state.dashboardDurationsEntities = action.payload.dashboardDurationsEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardLastTenFetched: (state, action) => {
            state.listLoading = false
            state.dashboardLastTenEntities = action.payload.dashboardLastTenEntities
            state.lastError = null
            state.internalError = null
        },
        // findDailyActivities
        outletTroubleshootsFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // getDaillyActivityBeforeCreate
        dailyActivityDataFetched: (state, action) => {
            state.actionsLoading = false
            state.dailyActivityDataByTaskIDTaskItemID = action.payload.dailyActivityDataByTaskIDTaskItemID
            state.lastError = null
            state.internalError = null
        },
        // findOutletTroubleshootDatas
        outletTroubleshootDatasFetched: (state, action) => {
            const { totalCountData, entitiesData } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entitiesData = entitiesData
            state.totalCountData = totalCountData
            state.stayOpen = true
        },
        // getOutletTroubleshootDataByID
        outletTroubleshootDataFetched: (state, action) => {
            state.actionsLoading = false
            state.outletTroubleshootDataByID = action.payload.outletTroubleshootDataByID
            state.lastError = null
            state.internalError = null
        },
        // createOutletTroubleshootData
        outletTroubleshootDataCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entitiesData.push(action.payload.outletTroubleshootData)
            state.stayOpen = false
        },
        // updateOutletTroubleshootData
        outletTroubleshootDataUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entitiesData = state.entitiesData.map(entity => {
                if (entity.id === action.payload.outletTroubleshootDataUpdated.troubleshoot_id) {
                    return action.payload.outletTroubleshootDataUpdated
                }
                return entity
            })
        },
        // deleteOutletTroubleshootData
        outletTroubleshootDataDeleted: (state, action) => {
            state.stayOpen = false
            state.lastError = null
            state.actionsLoading = false
            state.entitiesData = state.entitiesData.filter(el => el.id !== action.payload.id)
        },
        // outletTroubleshootDatasDeleteState
        outletTroubleshootDatasDeletedState: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        //setBatchResultStatus
        setBatchResult: (state, action) => {
            state.batchResult.push(action.payload.data)
        },
        // batchActionsFinished
        batchActionsFinished: (state) => {
            state.actionsLoading = false
            state.lastError = null
            state.stayOpen = true
        },
        // setInitBatch
        reInitBatch: (state) => {
            state.actionsLoading = false
            state.internalError = null
            state.lastError = null
            state.stayOpen = true
            state.batchResult = []
        },
        // set initialFilter
        reInitFilter: (state, action) => {
            state.initialFilter = action.payload.updateFilter
        }
    }
})

import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import * as app from '../../../../../../app/redux/app/app.reducers'

import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../../_helpers'
import { getCurrentMenuAccessByTokenAndMenuID, getMenusByMenuId } from '../../../../../../app/redux/app/app.api'

export function ModuleMenu() {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? `${!hasSubmenu &&
      'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : ''
  }
  const props = app.actions
  const dispatch = useDispatch()
  const didRequest = useRef(false)
  const { menus, menuId, currentUserState } = useSelector(
    (state) => ({
      menus: state.app.menus,
      menuId: state.app.menuId,
      currentUserState: state.auth.user
    }),
    shallowEqual
  )

  useEffect(() => {
    // fetch menu
    const requestCurrentMenu = async () => {
      try {
        if (!didRequest.current && menuId) {
          const response = await getMenusByMenuId(menuId)
          const menu = response.data.data

          dispatch(props.fulfillCurrentMenu(menu))
        }
      } catch (error) {
        console.error(error)
      }
      return () => (didRequest.current = true)
    }

    const requestCurrentMenuAccess = async () => {
      try {
        if (!didRequest.current && menuId) {
          const response = await getCurrentMenuAccessByTokenAndMenuID(currentUserState.role.role_id, menuId)
          const acess = response.data.data

          dispatch(props.fulfillCurrentMenuAccess(acess))
        }
      } catch (error) {
        console.error(error)
      }
      return () => (didRequest.current = true)
    }

    requestCurrentMenu()
    requestCurrentMenuAccess()

    // eslint-disable-next-line
  }, [menuId])

  return (
    <>
      {/* begin::Menu */}
      {/* begin::Menu section */}
      <li className='menu-section '>
        <h4 className='menu-text'>Menu</h4>
        <i className='menu-icon flaticon-more-v2'></i>
      </li>
      {/* end:: Menu section */}

      {/* begin::Sub Menu */}

      {
        menus &&
        menus.map((menu) => (
          menu.parent_id === '' ?
            <li
              key={menu.menu_id}
              className={`menu-item menu-item-submenu ${getMenuItemActive(`/${menu.path}`, true)}`}
              aria-haspopup='true'
              data-menu-toggle='hover'
              value={menu.menu_id}
            >
              <NavLink
                className='menu-link menu-toggle menu-url'
                to={`/${menu.path}`}
                menu-url={menu.menu_id}
              // onClick={() => dispatch(props.selectMenu(`${menu.menu_id}`))}
              >
                <span className='svg-icon menu-icon'>
                  <SVG src={toAbsoluteUrl(`${menu.icon}`)} />
                </span>
                <span className='menu-text'>{menu.name}</span>
                {menu.has_child === 't' ? <i className='menu-arrow' /> : ''}
              </NavLink>
              {
                menu.has_child === 't' ?
                  menus.map((menuChild) => (
                    menuChild.parent_id !== '' && menuChild.parent_id === menu.menu_id ?
                      <div key={menuChild.menu_id} className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                          <li
                            className={`menu-item ${getMenuItemActive(`/${menuChild.path}`)} menu-child-url`}
                            aria-haspopup="true"
                            menu-child-url={menuChild.menu_id}
                          >
                            <NavLink
                              className="menu-link"
                              to={`/${menuChild.path}`}
                              onClick={() => dispatch(props.selectMenu(`${menuChild.menu_id}`))}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{menuChild.name}</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      : ''))
                  : ''
              }
            </li>
            : ''

        ))
      }
    </>
  )
}
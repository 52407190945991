/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { Divider } from '@material-ui/core'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export function MokaDashboard({ className }) {
    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Coming soon...</Tooltip>}
        >
            <div className={`card card-custom bg-gray-100 ${className}`}>
                {/* Header */}
                <div className="card-header border-0 bg-gray-100">
                    <h3 className="card-title">MOKA</h3>
                </div>
                {/* Body */}
                <div className="card-body p-0 position-relative overflow-hidden">
                    {/* Sales Summary */}
                    <div className="container-fluid">
                        <span className='text-muted'>SALES SUMMARY</span>
                        <Divider />
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Gross Sales</span><br />
                            <a
                                href="#"
                                className="text-primary font-weight-bold font-size-h6 mt-2"
                            >
                                Rp. 100.000.000.000
                            </a>
                        </div>
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Net Sales</span><br />
                            <a
                                href="#"
                                className="text-primary font-weight-bold font-size-h6 mt-2"
                            >
                                Rp. 100.000.000.000
                            </a>
                        </div>
                        <div className="col bg-white px-3 py-3 rounded-xl mb-1">
                            <span className="card-title text-muted mb-3">Gross Profit</span><br />
                            <a
                                href="#"
                                className="text-primary font-weight-bold font-size-h6 mt-2"
                            >
                                Rp. 100.000.000.000
                            </a>
                        </div>
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Gross Sales</span><br />
                            <a
                                href="#"
                                className="text-primary font-weight-bold font-size-h6 mt-2"
                            >
                                Rp. 100.000.000.000
                            </a>
                        </div>
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Net Sales</span><br />
                            <a
                                href="#"
                                className="text-primary font-weight-bold font-size-h6 mt-2"
                            >
                                Rp. 100.000.000.000
                            </a>
                        </div>
                        <div className="col bg-white px-3 py-3 rounded-xl mb-1">
                            <span className="card-title text-muted mb-3">Gross Profit</span><br />
                            <a
                                href="#"
                                className="text-primary font-weight-bold font-size-h6 mt-2"
                            >
                                Rp. 100.000.000.000
                            </a>
                        </div>
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Daily Gross Sales Ammount</span><br />
                            Chart Here
                        </div>
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Day of The Week Gross Sales Ammount</span><br />
                            Chart Here
                        </div>
                        <div className="col bg-white px-3 py-3 rounded-xl mb-1">
                            <span className="card-title text-muted mb-3">Hourly Gross Sales Ammount</span><br />
                            Chart Here
                        </div>
                    </div>
                    {/* Sales Summary */}

                    {/* Item Summary */}
                    <div className="container-fluid mt-10">
                        <span className='text-muted'>ITEM SUMMARY</span>
                        <Divider />
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Top Items</span><br />
                            Table Here
                        </div>
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Category by Volume</span><br />
                            Chart Here
                        </div>
                        <div className="col bg-white px-3 py-3 rounded-xl mb-1">
                            <span className="card-title text-muted mb-3">Category by Sales</span><br />
                            Chart Here
                        </div>
                    </div>
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap mt-4">
                        <div className="col bg-white px-3 py-3 rounded-xl mr-1 mb-1">
                            <span className="card-title text-muted mb-3">Top Items by Category</span><br />
                            Chart Here
                        </div>
                    </div>
                    {/* Item Summary */}

                    {/* Resize */}
                    <div className="resize-triggers">
                        <div className="expand-trigger">
                            <div style={{ width: "411px", height: "461px" }} />
                        </div>
                        <div className="contract-trigger" />
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    )
}
import { createSlice } from '@reduxjs/toolkit'

const initialMasterDatasState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    datasEntityDetails: undefined,
    parameterEntity: undefined,
    parameterDatas: [],
    lastError: null,
    internalError: null,
    stayOpen: true,
    batchResult: []
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const MasterDatasReducers = createSlice({
    name: 'datas',
    initialState: initialMasterDatasState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getParameterEntity
        parameterFetched: (state, action) => {
            state.actionsLoading = false
            state.parameterEntity = action.payload.parameterEntity
            state.lastError = null
            state.internalError = null
            state.parameterDatas = []
        },
        // getParameterDatas
        parameterDatasFetched: (state, action) => {
            state.actionsLoading = false
            state.parameterDatas = action.payload.parameterDatas
            state.lastError = null
            state.internalError = null
        },
        // getDataById
        dataFetched: (state, action) => {
            state.actionsLoading = false
            state.datasEntityDetails = action.payload.datasEntityDetails
            state.lastError = null
            state.internalError = null
        },
        // findDatas
        datasFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // createData
        dataCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entities.push(action.payload.datas)
            state.stayOpen = false
        },
        // updateData
        dataUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.datas.data_id) {
                    return action.payload.datas
                }
                return entity
            })
        },
        // deleteData
        dataDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.entities = state.entities.filter(el => el.id !== action.payload.data_id)
        },
        // //deleteDatas
        // datasDeleted: (state, action) => {
        //     state.lastError = null
        //     state.actionsLoading = false
        //     state.stayOpen = false
        //     state.entities = state.entities.filter(
        //         el => !action.payload.ids.includes(el.id)
        //     )
        // },
        // datasUpdateState
        datasStatusUpdated: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // datasDeleteState
        datasDeletedState: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // setBatchResultStatus
        setBatchResult: (state, action) => {
            state.batchResult.push(action.payload.data)
        },
        // batchActionsFinished
        batchActionsFinished: (state) => {
            state.actionsLoading = false
            state.lastError = null
            state.stayOpen = true
        },
        // setInitBatch
        reInitBatch: (state) => {
            state.actionsLoading = false
            state.internalError = null
            state.lastError = null
            state.stayOpen = true
            state.batchResult = []
        }
    }
})

import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as auth from '../redux/auth/auth.reducers'
import * as app from '../redux/app/app.reducers'
import { UsersReducers } from '../redux/users/users.reducers'
import { GroupsReducers } from '../redux/groups/groups.reducers'
import { GroupsAccessReducers } from '../redux/groups-access/groupAccess.reducers'
import { LogsReducers } from '../redux/logs/logs.reducers'
import { ModulesReducers } from '../redux/modules/modules.reducers'
import { MenusReducers } from '../redux/menus/menus.reducers'
import { SitesReducers } from '../redux/sites/sites.reducers'
import { ParamsReducers } from '../redux/params/params.reducers'
import { AppInfoReducers } from '../redux/app_infos/app_infos.reducers'
import { MasterDatasReducers } from '../redux/master-datas/datas.reducers'
import { MokaReducers } from '../redux/moka/moka.reducers'
import { OutletReducers } from '../redux/outlets/outlets.reducers'
import { DailyActivityReducers } from '../redux/daily-activity/daily-activity.reducers'
import { OfficeTroubleshootReducers } from '../redux/office-troubleshoot/office-troubleshoot.reducers'
import { OutletTroubleshootReducers } from '../redux/outlet-troubleshoot/outlet-troubleshoot.reducers'
import { ESBMasterProductReducers } from '../redux/esb-master-product/esb-master-product.reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

const rootReducer = combineReducers({
  auth: auth.authReducer,
  app: app.AppReducer,
  users: UsersReducers.reducer,
  groups: GroupsReducers.reducer,
  groupsAccess: GroupsAccessReducers.reducer,
  logs: LogsReducers.reducer,
  modules: ModulesReducers.reducer,
  menus: MenusReducers.reducer,
  sites: SitesReducers.reducer,
  parameters: ParamsReducers.reducer,
  app_infos: AppInfoReducers.reducer,
  datas: MasterDatasReducers.reducer,
  moka_account: MokaReducers.reducer,
  moka_outlet: OutletReducers.reducer,
  daily_activity: DailyActivityReducers.reducer,
  office_troubleshoot: OfficeTroubleshootReducers.reducer,
  outlet_troubleshoot: OutletTroubleshootReducers.reducer,
  ebs_master_product: ESBMasterProductReducers.reducer,
})

export default persistReducer(persistConfig, rootReducer)
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const actionTypes = {
  QueryParamsLoaded: '[Query Params Loaded] App API',
  ModuleLoaded: '[Module Loaded] App API',
  ModuleSelected: '[Module Selected] App API',
  SiteLoaded: '[Site Loaded] App API',
  SiteSelected: '[Site Selected] App API',
  MenuLoaded: '[Menu Loaded] App API',
  MenuSelected: '[Menu Selected] App API',
  CurrentMenuLoaded: '[Current Menu Loaded] App API',
  CurrentMenuAccessLoaded: '[Current Menu Access Loaded] App API',
  FilterUserRolesLoaded: '[Load User Roles For Filter] App API',
  FilterUsersDailyLoaded: '[Load User For Filter Daily] App API',
  FilterUsersLoaded: '[Load User For Filter] App API',
  FilterModulesLoaded: '[Load Module For Filter] App API',
  FilterMenusLoaded: '[Load Menu For Filter] App API',
  FilterParamsLoaded: '[Load Parameter For Filter] App API',
  FilterTeskCategories: '[Load Task Category For Filter] App API',
  FilterAppNameForFilter: '[Load App Name For Filter] App API',
  FilterAppNameForInput: '[Load App Name For Input] App API',
  FilterAppNameAllStatus: '[Load App Name All Status] App API',
  FilterTypeInfoForFilter: '[Load Type Info For Filter] App API',
  FilterTypeInfoForInput: '[Load Type Info For Input] App API',
  FilterTypeInfoAllStatus: '[Load Type Info All Status] App API',
  FilterTaskItems: '[Load Task Item For Filter] App API',
  FilterTaskItemsForInputDailyActivity: '[Load Task Item For Filter Input Daily Activity] App API',
  FilterTaskItemsForInputDailyActivityAllStatus: '[Load Task Item For Filter Input Daily Activity All Status] App API',
  FilterTroubleshootStatusForFilter: '[Load Troubleshoot Status For Filter] App API',
  FilterTroubleshootUserDivisionForInput: '[Load Troubleshoot User Division For Input] App API',
  FilterTroubleshootUserDivisionAllStatusForInput: '[Load Troubleshoot User Division All Status For Input] App API',
  FilterTroubleshootUserForInput: '[Load Troubleshoot User For Input] App API',
  FilterTroubleshootUserAllStatusForInput: '[Load Troubleshoot User All Status For Input] App API',
  FilterTroubleshootOutletBranchForInput: '[Load Troubleshoot Outlet Branch For Input] App API',
  FilterTroubleshootOutletBranchAllStatusForInput: '[Load Troubleshoot Outlet Branch All Status For Input] App API',
  FilterTroubleshootOutletPICForInput: '[Load Troubleshoot Outlet PIC For Input] App API',
  FilterTroubleshootOutletPICAllStatusForInput: '[Load Troubleshoot Outlet PIC All Status For Input] App API',
  Logout: '[Logout] Action',
}

const initialAppState = {
  modules: undefined,
  moduleId: undefined,
  sites: undefined,
  siteId: undefined,
  menus: undefined,
  currentMenu: undefined,
  menuId: undefined,
  currentMenuAccess: undefined,
  userRolesForFilter: undefined,
  usersForFilterDaily: undefined,
  usersForFilter: undefined,
  modulesForFilter: undefined,
  menusForFilter: undefined,
  paramsForFilter: undefined,
  taskCategoriesForFilter: undefined,
  appNameForSelect: undefined,
  appNameForInput: undefined,
  appNameAllStatusForSelect: undefined,
  typeInfoForSelect: undefined,
  typeInfoForInput: undefined,
  typeInfoAllStatusForSelect: undefined,
  taskItemsForFilter: undefined,
  taskItemsForInput: undefined,
  taskItemsForInputDailyActivity: undefined,
  taskItemsForInputDailyActivityAllStatus: undefined,
  troubleshootStatusForFilter: undefined,
  troubleshootUserDivisionForInputOfficeTroubleshootData: undefined,
  troubleshootUserDivisionAllStatusForInputOfficeTroubleshootData: undefined,
  troubleshootUserForInputOfficeTroubleshootData: undefined,
  troubleshootUserAllStatusForInputOfficeTroubleshootData: undefined,
  troubleshootOutletBranchForInputOfficeTroubleshootData: undefined,
  troubleshootOutletBranchAllStatusForInputOfficeTroubleshootData: undefined,
  troubleshootOutletPICForInputOfficeTroubleshootData: undefined,
  troubleshootOutletPICAllStatusForInputOfficeTroubleshootData: undefined,
}

export const AppReducer = persistReducer(
  {
    storage, key: 'orins-v2', whitelist: ['modules', 'moduleId', 'sites', 'menus', 'siteId', 'menuId', 'currentMenu', 'currentMenuAccess', 'userRolesForFilter', 'usersForFilterDaily', 'usersForFilter', 'modulesForFilter', 'menusForFilter', 'paramsForFilter', 'taskCategoriesForFilter', 'typeInfoForSelect', 'taskItemsForFilter', 'taskItemsForFilter', 'taskItemsForInputDailyActivity', 'taskItemsForInputDailyActivityAllStatus', 'troubleshootStatusForFilter', 'troubleshootUserDivisionForInputOfficeTroubleshootData', 'troubleshootUserDivisionAllStatusForInputOfficeTroubleshootData', 'troubleshootUserForInputOfficeTroubleshootData', 'troubleshootUserAllStatusForInputOfficeTroubleshootData', 'troubleshootOutletBranchForInputOfficeTroubleshootData', 'troubleshootOutletBranchAllStatusForInputOfficeTroubleshootData', 'troubleshootOutletPICForInputOfficeTroubleshootData', 'troubleshootOutletPICAllStatusForInputOfficeTroubleshootData']
  },
  (state = initialAppState, action) => {
    switch (action.type) {
      case actionTypes.QueryParamsLoaded: {
        const { queryParams } = action.payload
        return { ...state, queryParams }
      }
      case actionTypes.ModuleLoaded: {
        const { modules } = action.payload
        return { ...state, modules }
      }
      case actionTypes.ModuleSelected: {
        const { moduleId } = action.payload
        return { ...state, moduleId, sites: undefined, siteId: undefined, menus: undefined, currentMenu: undefined, menuId: undefined }
      }
      case actionTypes.SiteLoaded: {
        const { sites } = action.payload
        return { ...state, sites }
      }
      case actionTypes.SiteSelected: {
        const { siteId } = action.payload
        return { ...state, siteId }
      }
      case actionTypes.MenuLoaded: {
        const { menus } = action.payload
        return { ...state, menus }
      }
      case actionTypes.MenuSelected: {
        const { menuId, currentMenu } = action.payload
        return { ...state, menuId, currentMenu }
      }
      case actionTypes.CurrentMenuLoaded: {
        const { currentMenu } = action.payload
        return { ...state, currentMenu }
      }
      case actionTypes.CurrentMenuAccessLoaded: {
        const { currentMenuAccess } = action.payload
        return { ...state, currentMenuAccess }
      }
      case actionTypes.FilterUserRolesLoaded: {
        const { userRolesForFilter } = action.payload
        return { ...state, userRolesForFilter }
      }
      case actionTypes.FilterUsersLoaded: {
        const { usersForFilter } = action.payload
        return { ...state, usersForFilter }
      }
      case actionTypes.FilterUsersDailyLoaded: {
        const { usersForFilterDaily } = action.payload
        return { ...state, usersForFilterDaily }
      }
      case actionTypes.FilterModulesLoaded: {
        const { modulesForFilter } = action.payload
        return { ...state, modulesForFilter }
      }
      case actionTypes.FilterMenusLoaded: {
        const { menusForFilter } = action.payload
        return { ...state, menusForFilter }
      }
      case actionTypes.FilterParamsLoaded: {
        const { paramsForFilter } = action.payload
        return { ...state, paramsForFilter }
      }
      case actionTypes.FilterTeskCategories: {
        const { taskCategoriesForFilter } = action.payload
        return { ...state, taskCategoriesForFilter }
      }
      case actionTypes.FilterTaskItems: {
        const { taskItemsForFilter } = action.payload
        return { ...state, taskItemsForFilter }
      }
      case actionTypes.FilterAppNameForFilter: {
        const { appNameForSelect } = action.payload
        return { ...state, appNameForSelect }
      }
      case actionTypes.FilterAppNameForInput: {
        const { appNameForInput } = action.payload
        return { ...state, appNameForInput }
      }
      case actionTypes.FilterAppNameAllStatus: {
        const { appNameAllStatusForSelect } = action.payload
        return { ...state, appNameAllStatusForSelect }
      }
      case actionTypes.FilterTypeInfoForFilter: {
        const { typeInfoForSelect } = action.payload
        return { ...state, typeInfoForSelect }
      }
      case actionTypes.FilterTypeInfoForInput: {
        const { typeInfoForInput } = action.payload
        return { ...state, typeInfoForInput }
      }
      case actionTypes.FilterTypeInfoAllStatus: {
        const { typeInfoAllStatusForSelect } = action.payload
        return { ...state, typeInfoAllStatusForSelect }
      }
      case actionTypes.FilterTaskItemsForInputDailyActivity: {
        const { taskItemsForInputDailyActivity } = action.payload
        return { ...state, taskItemsForInputDailyActivity }
      }
      case actionTypes.FilterTaskItemsForInputDailyActivityAllStatus: {
        const { taskItemsForInputDailyActivityAllStatus } = action.payload
        return { ...state, taskItemsForInputDailyActivityAllStatus }
      }
      case actionTypes.FilterTroubleshootStatusForFilter: {
        const { troubleshootStatusForFilter } = action.payload
        return { ...state, troubleshootStatusForFilter }
      }
      case actionTypes.FilterTroubleshootUserDivisionForInput: {
        const { troubleshootUserDivisionForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootUserDivisionForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootUserDivisionAllStatusForInput: {
        const { troubleshootUserDivisionAllStatusForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootUserDivisionAllStatusForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootUserForInput: {
        const { troubleshootUserForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootUserForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootUserAllStatusForInput: {
        const { troubleshootUserAllStatusForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootUserAllStatusForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootOutletBranchForInput: {
        const { troubleshootOutletBranchForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootOutletBranchForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootOutletBranchAllStatusForInput: {
        const { troubleshootOutletBranchAllStatusForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootOutletBranchAllStatusForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootOutletPICForInput: {
        const { troubleshootOutletPICForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootOutletPICForInputOfficeTroubleshootData }
      }
      case actionTypes.FilterTroubleshootOutletPICAllStatusForInput: {
        const { troubleshootOutletPICAllStatusForInputOfficeTroubleshootData } = action.payload
        return { ...state, troubleshootOutletPICAllStatusForInputOfficeTroubleshootData }
      }
      case actionTypes.Logout: {
        return initialAppState
      }

      default:
        return state
    }
  }
)

export const actions = {
  fulfillQueryParams: (queryParams) => ({
    type: actionTypes.QueryParamsLoaded,
    payload: { queryParams }
  }),
  fetchModule: (modules) => ({
    type: actionTypes.ModuleLoaded,
    payload: { modules }
  }),
  selectModule: (moduleId) => ({
    type: actionTypes.ModuleSelected,
    payload: { moduleId }
  }),
  fetchSite: (sites) => ({
    type: actionTypes.SiteLoaded,
    payload: { sites }
  }),
  selectSite: (siteId) => ({
    type: actionTypes.SiteSelected,
    payload: { siteId }
  }),
  fetchMenus: (menus) => ({
    type: actionTypes.MenuLoaded,
    payload: { menus }
  }),
  selectMenu: (menuId) => ({
    type: actionTypes.MenuSelected,
    payload: { menuId }
  }),
  requestCurrentMenuAccess: (currentMenuAccess) => ({
    type: actionTypes.CurrentMenuAccessRequested,
    payload: { currentMenuAccess },
  }),
  fulfillCurrentMenu: (currentMenu) => ({
    type: actionTypes.CurrentMenuLoaded,
    payload: { currentMenu }
  }),
  fulfillCurrentMenuAccess: (currentMenuAccess) => ({
    type: actionTypes.CurrentMenuAccessLoaded,
    payload: { currentMenuAccess }
  }),
  fulfillFilterUserRoles: (userRolesForFilter) => ({
    type: actionTypes.FilterUserRolesLoaded,
    payload: { userRolesForFilter }
  }),
  fulfillFilterlUsers: (usersForFilter) => ({
    type: actionTypes.FilterUsersLoaded,
    payload: { usersForFilter }
  }),
  fulfillFilterlUsersDaily: (usersForFilterDaily) => ({
    type: actionTypes.FilterUsersDailyLoaded,
    payload: { usersForFilterDaily }
  }),
  fulfillFilterlModules: (modulesForFilter) => ({
    type: actionTypes.FilterModulesLoaded,
    payload: { modulesForFilter }
  }),
  fulfillFilterlMenus: (menusForFilter) => ({
    type: actionTypes.FilterMenusLoaded,
    payload: { menusForFilter }
  }),
  fulfillFilterlParams: (paramsForFilter) => ({
    type: actionTypes.FilterParamsLoaded,
    payload: { paramsForFilter }
  }),
  fulfillFilterTaskCategories: (taskCategoriesForFilter) => ({
    type: actionTypes.FilterTeskCategories,
    payload: { taskCategoriesForFilter }
  }),
  fulfillFilterItems: (taskItemsForFilter) => ({
    type: actionTypes.FilterTaskItems,
    payload: { taskItemsForFilter }
  }),
  fulfillSelectAppNamesForFilter: (appNameForSelect) => ({
    type: actionTypes.FilterAppNameForFilter,
    payload: { appNameForSelect }
  }),
  fulfillSelectAppNamesForInput: (appNameForInput) => ({
    type: actionTypes.FilterAppNameForInput,
    payload: { appNameForInput }
  }),
  fulfillSelectAppNameAllStatus: (appNameAllStatusForSelect) => ({
    type: actionTypes.FilterAppNameAllStatus,
    payload: { appNameAllStatusForSelect }
  }),
  fulfillSelectTypeAppsForFilter: (typeInfoForSelect) => ({
    type: actionTypes.FilterTypeInfoForFilter,
    payload: { typeInfoForSelect }
  }),
  fulfillSelectTypeAppsForInput: (typeInfoForInput) => ({
    type: actionTypes.FilterTypeInfoForInput,
    payload: { typeInfoForInput }
  }),
  fulfillSelectTypeAppAllStatus: (typeInfoAllStatusForSelect) => ({
    type: actionTypes.FilterTypeInfoAllStatus,
    payload: { typeInfoAllStatusForSelect }
  }),
  fulfillInputTaskItems: (taskItemsForInputDailyActivity) => ({
    type: actionTypes.FilterTaskItemsForInputDailyActivity,
    payload: { taskItemsForInputDailyActivity }
  }),
  fulfillInputTaskItemsAllStatus: (taskItemsForInputDailyActivityAllStatus) => ({
    type: actionTypes.FilterTaskItemsForInputDailyActivityAllStatus,
    payload: { taskItemsForInputDailyActivityAllStatus }
  }),
  fulfillFilterTroubleshootStatus: (troubleshootStatusForFilter) => ({
    type: actionTypes.FilterTroubleshootStatusForFilter,
    payload: { troubleshootStatusForFilter }
  }),
  fulfillFilterTroubleshootUserDivision: (troubleshootUserDivisionForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootUserDivisionForInput,
    payload: { troubleshootUserDivisionForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootUser: (troubleshootUserForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootUserForInput,
    payload: { troubleshootUserForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootUserDivisionAllStatus: (troubleshootUserDivisionAllStatusForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootUserDivisionAllStatusForInput,
    payload: { troubleshootUserDivisionAllStatusForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootUserAllStatus: (troubleshootUserAllStatusForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootUserAllStatusForInput,
    payload: { troubleshootUserAllStatusForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootOutletBranch: (troubleshootOutletBranchForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootOutletBranchForInput,
    payload: { troubleshootOutletBranchForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootOutletPIC: (troubleshootOutletPICForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootOutletPICForInput,
    payload: { troubleshootOutletPICForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootOutletBranchAllStatus: (troubleshootOutletBranchAllStatusForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootOutletBranchAllStatusForInput,
    payload: { troubleshootOutletBranchAllStatusForInputOfficeTroubleshootData }
  }),
  fulfillFilterTroubleshootOutletPICAllStatus: (troubleshootOutletPICAllStatusForInputOfficeTroubleshootData) => ({
    type: actionTypes.FilterTroubleshootOutletPICAllStatusForInput,
    payload: { troubleshootOutletPICAllStatusForInputOfficeTroubleshootData }
  }),
  logout: () => ({
    type: actionTypes.Logout
  })
}

export function* appSagas() {

}
import React, { Suspense, lazy, useRef, useEffect } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector, connect, useDispatch } from 'react-redux'

import * as app from '../redux/app/app.reducers'
import { getModulesByToken } from '../redux/app/app.api'

import { DashboardPage } from '../pages/DashboardPage'
import { LayoutSplashScreen, ContentRoute } from '../../_metronic/layout'

const ESBDataModulePage = lazy(() =>
  import("../pages/modules/esb-data/ESBDataModulePage")
)
const OrinsShopModulePage = lazy(() =>
  import("../pages/modules/orins-shop/OrinsShopModulePage")
)
const ITModulePage = lazy(() =>
  import("../pages/modules/it-module/ITModulePage")
)
const UserProfilePage = lazy(() =>
  import("../pages/user-profile/UserProfilePage")
)
const SettingsPage = lazy(() =>
  import("../pages/modules/settings/SettingsPage")
)

function PrivateRoutes() {
  const props = app.actions
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const { initapp, authtoken } = useSelector(
    ({ app, auth }) => ({
      initapp: app,
      authtoken: auth.authToken
    }),
    shallowEqual
  )
  // We should request initapp before rendering the application
  useEffect(() => {
    const requestApp = async () => {
      try {
        if (!didRequest.current) {
          const response = await getModulesByToken()
          const modules = response.data.data

          dispatch(props.fetchModule(modules))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      }

      return () => (didRequest.current = true)
    }

    if (initapp) {
      requestApp()
    }
    if (!authtoken) {
      dispatch(props.logout())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from='/' to='/dashboard' />
        }
        <ContentRoute path='/dashboard' component={DashboardPage} />
        <Route path="/esb-data" component={ESBDataModulePage} />
        <Route path="/orins-shop" component={OrinsShopModulePage} />
        <Route path="/it-module" component={ITModulePage} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Route path="/general" component={SettingsPage} />
        <Redirect to="error/error" />
      </Switch>
    </Suspense>
  )
}

export default connect(null, app.actions)(PrivateRoutes)

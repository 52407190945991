/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import Clock from 'react-live-clock'

export function HeaderMenu({ layoutProps }) {
    const days = ['Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    const today = new Date()
    const day = days[today.getDay()]
    const month = months[today.getMonth()]
    return <div
        id='kt_header_menu'
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <div className='d-flex flex-grow-1 align-items-center p-4 rounded'>
            <div className='mr-4 flex-shrink-0 text-center' style={{ width: '40px' }}>
                <i className='icon-2x text-primary flaticon2-calendar-9'></i>
            </div>
            <div className='text-primary font-weight-boldest'>
                {day}, {today.getDate()} {month} {today.getFullYear()}
            </div>
        </div>
        <div className='d-flex flex-grow-1 align-items-center p-4 rounded'>
            <div className='mr-4 flex-shrink-0 text-center' style={{ width: '40px' }}>
                <i className='icon-2x text-primary flaticon-clock-1'></i>
            </div>
            <div className='text-primary font-weight-boldest'>
                <Clock format={'HH:mm:ss'} ticking={true} timezone={'Asia/Jakarta'} />
            </div>
        </div>
    </div>
}

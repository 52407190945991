import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialDailyActivityState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    dashboardCategoriesEntities: null,
    dashboardCategoriesUserEntities: null,
    dashboardItemsEntities: null,
    dashboardItemsUserEntities: null,
    dashboardTopItemsByCategoriesEntities: null,
    activityByID: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true,
    batchResult: {
        id: null,
        result: null,
        status: null
    },
    initialFilter: {
        filter: {
            user_id: "",
            task_category_id: "",
            task_item_id: "",
            daterange: moment.utc(moment().startOf('month')).format('YYYY-MM-DD HH:mm:ss') + '[-]' + moment.utc(moment().endOf('month')).format('YYYY-MM-DD HH:mm:ss'),
            daterange_label: moment.utc(moment().endOf('year')).format('YYYY'),
            start: moment().startOf('month'),
            end: moment().endOf('month'),
            status: "",
            search: ""
        },
        sortOrder: "desc",
        sortField: "task_date",
        pageNumber: 1,
        pageSize: 10
    }
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const DailyActivityReducers = createSlice({
    name: 'daily_activity',
    initialState: initialDailyActivityState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getDashboard
        dashboardCategoriesFetched: (state, action) => {
            state.listLoading = false
            state.dashboardCategoriesEntities = action.payload.dashboardCategoriesEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardCategoriesUserFetched: (state, action) => {
            state.listLoading = false
            state.dashboardCategoriesUserEntities = action.payload.dashboardCategoriesUserEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardItemsFetched: (state, action) => {
            state.listLoading = false
            state.dashboardItemsEntities = action.payload.dashboardItemsEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardItemsUserFetched: (state, action) => {
            state.listLoading = false
            state.dashboardItemsUserEntities = action.payload.dashboardItemsUserEntities
            state.lastError = null
            state.internalError = null
        },
        dashboardTopItemsByCategoriesFetched: (state, action) => {
            state.listLoading = false
            state.dashboardTopItemsByCategoriesEntities = action.payload.dashboardTopItemsByCategoriesEntities
            state.lastError = null
            state.internalError = null
        },
        // getActivity
        activityFetched: (state, action) => {
            state.actionsLoading = false
            state.activityByID = action.payload.activityByID
            state.lastError = null
            state.internalError = null
        },
        // findActivities
        activitiesFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // createActivity
        activityCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entities.push(action.payload.activity)
            state.stayOpen = false
        },
        // updateActivity
        activityUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.activity.id) {
                    return action.payload.activity
                }
                return entity
            })
        },
        // deleteActivity
        activityDeleted: (state, action) => {
            state.stayOpen = false
            state.lastError = null
            state.actionsLoading = false
            state.entities = state.entities.filter(el => el.id !== action.payload.id)
        },
        // deleteActivities
        activitiesDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            )
        },
        // activitiesUpdateState
        activitiesStatusUpdated: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // activitiesDeleteState
        activitiesDeletedState: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // setBatchResultStatus
        setBatchResult: (state, action) => {
            state.batchResult = action.payload.data
        },
        // batchActionsFinished
        batchActionsFinished: (state) => {
            state.actionsLoading = false
            state.lastError = null
            state.stayOpen = true
        },
        // setInitBatch
        reInitBatch: (state) => {
            state.actionsLoading = false
            state.lastError = null
            state.stayOpen = true
            state.batchResult.id = null
            state.batchResult.result = null
            state.batchResult.status = null
        },
        // set initialFilter
        reInitFilter: (state, action) => {
            state.initialFilter = action.payload.updateFilter
        }
    }
})

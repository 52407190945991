import React, { useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch } from 'react-redux'

import * as auth from './auth.reducers'
import { getUserByToken, getIPAddr } from './auth.api'

import { LayoutSplashScreen } from '../../../_metronic/layout'

function AuthInit(props) {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  )

  // We should request auth by authToken before rendering the application
  useEffect(() => {
    const requestAuth = async () => {
      try {
        if (!didRequest.current) {
          const response = await getUserByToken()
          const user = response.data.data

          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const requestIPAddr = async () => {
      try {
        const response = await getIPAddr()
        const ipAddr = response.data.IPv4

        dispatch(props.fulfillIP(ipAddr))
      } catch (error) {
        console.error(error)
      }
    }

    if (authToken) {
      requestAuth()
      requestIPAddr()
    }
    else {
      dispatch(props.fulfillUser(null))
      dispatch(props.fulfillIP(null))
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connect(null, auth.actions)(AuthInit)

import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import store, { persistor } from './app/setup/store'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'socicon/css/socicon.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import App from './App'
import * as _redux from './app/setup'

import './index.scss'
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css'
import './_metronic/_assets/plugins/flaticon/flaticon.css'
import './_metronic/_assets/plugins/flaticon2/flaticon.css'
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from './_metronic/layout'
import { MetronicI18nProvider } from './_metronic/i18n'

const { PUBLIC_URL } = process.env
_redux.setupAxios(axios, store)

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)

import { createSlice } from '@reduxjs/toolkit'

const initialGroupsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    groupForEdit: undefined,
    userRoleModulesForEdit: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true,
    batchResult: []
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const GroupsReducers = createSlice({
    name: 'groups',
    initialState: initialGroupsState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getGroupById
        groupFetched: (state, action) => {
            state.actionsLoading = false
            state.groupForEdit = action.payload.groupForEdit
            state.userRoleModulesForEdit = action.payload.userRoleModulesForEdit
            state.lastError = null
            state.internalError = null
        },
        // findGroups
        groupsFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // createGroup
        groupCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entities.push(action.payload.group)
            state.stayOpen = false
        },
        // updateGroup
        groupUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.group.role_id) {
                    return action.payload.group
                }
                return entity
            })
        },
        // deleteGroup
        groupDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.entities = state.entities.filter(el => el.id !== action.payload.role_id)
        },
        // deleteGroups
        groupsDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            )
        },
        // groupsUpdateState
        groupsStatusUpdated: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // groupsDeleteState
        groupsDeletedState: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // setBatchResultStatus
        setBatchResult: (state, action) => {
            state.batchResult.push(action.payload.data)
        },
        // batchActionsFinished
        batchActionsFinished: (state) => {
            state.actionsLoading = false
            state.lastError = null
            state.stayOpen = true
        },
        // setInitBatch
        reInitBatch: (state) => {
            state.actionsLoading = false
            state.internalError = null
            state.lastError = null
            state.stayOpen = true
            state.batchResult = []
        }
    }
})

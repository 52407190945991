import axios from 'axios'

export const api = {
    APP_LOGS_URL: `${process.env.REACT_APP_APP_API_URL}/log`,
}

export function getLog(id) {
    return axios.get(`${api.APP_LOGS_URL}/${id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function findLogs(queryParams) {
    return axios.post(`${api.APP_LOGS_URL}/find`, { queryParams }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function fetchCountActivitiesByUserID() {
    return axios.get(`${api.APP_LOGS_URL}/count-activity-by-user-id`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function findActivities(limit) {
    return axios.get(`${api.APP_LOGS_URL}/activity/${limit}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function fetchActivitiesNext(start, limit) {
    return axios.get(`${api.APP_LOGS_URL}/activity-next/${start}/${limit}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function exportLogs(queryParams) {
    return axios.post(`${api.APP_LOGS_URL}/exports`, { queryParams }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

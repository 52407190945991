/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function ListMidTerm({ className }) {
    return (
        <>
            <div className={`card card-custom ${className}`}>
                {/* Header */}
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-primary">Plan Project</h3>
                </div>
                {/* Body */}
                <div className="card-body pt-0">
                    <div className='table-responsive' style={{ maxHeight: '500px' }}>
                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Phone.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        New Orins POS Mobile Applications
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                        ...
                                    </span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                ...
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Phone.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Orins Attendance Mobile Applications
                                    </a>
                                    <span className="text-muted font-weight-bold">...</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                ...
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Laptop.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Implement Orins Sales to ERP Orins CORP
                                    </a>
                                    <span className="text-muted font-weight-bold">...</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                ...
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Laptop.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Implement MOKA SALES API to ERP Orins CORP
                                    </a>
                                    <span className="text-muted font-weight-bold">...</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                ...
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Laptop.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Integrating Company entity to ERP Orins CORP
                                    </a>
                                    <span className="text-muted font-weight-bold">...</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                ...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Tooltip } from '@material-ui/core'

import * as auth from '../../redux/auth/auth.reducers'
import { requestResendPassword } from '../../redux/auth/auth.api'

const initialValues = {
    email: '',
    password: '',
}

function ForgotPasswordFormContainer(props) {
    const { token } = useParams()
    initialValues.token = token

    const [isRequested, setIsRequested] = useState(false)
    const ForgotSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .required('*Email required!'),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            )
            .required('*Password required!'),
    })

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid'
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid'
        }

        return ''
    }

    const resendPassword = (values, setStatus, setSubmitting) => {
        setTimeout(() => {
            requestResendPassword(values.email, values.password)
                .then((response) => {
                    const { meta } = response.data
                    if (meta.code === 200) {
                        setIsRequested(true)
                        document.getElementById('success_info').style = 'display: block'
                    } else {
                        setStatus(meta.message)
                        setTimeout(function () {
                            setStatus(undefined)
                        }, 4e3)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setStatus(err)
                    setTimeout(function () {
                        setStatus(undefined)
                    }, 4e3)
                })
            setSubmitting(false)
        }, 1e1)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            resendPassword(values, setStatus, setSubmitting)
        },
    })

    const [passwordShown, setPasswordShown] = useState(false)
    const [labelPassword, setLabelPassword] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
        setLabelPassword(labelPassword ? false : true)
    }

    return (
        <div className='login-form login-forgot' style={{ display: 'block' }}>
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>Forgotten Password ?</h3>
                <div className='text-muted font-weight-bold'>
                    Enter your email and create new password.
                </div>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
            >
                {formik.status && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                        <div className='alert-text font-weight-bold'>
                            {formik.status}
                        </div>
                    </div>
                )}
                <div id='success_info' className='mb-10 alert alert-custom alert-light-success alert-dismissible' style={{ display: 'none' }}>
                    <div className='alert-text font-weight-bold '>
                        Password Reset.
                    </div>
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <input
                        type='email'
                        placeholder='Email'
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            'email'
                        )}`}
                        name='email'
                        {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <input
                        type={passwordShown ? 'text' : 'password'}
                        placeholder='New Password'
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            'password'
                        )}`}
                        name='password'
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                    ) : null}
                    <br />
                    <Tooltip title={labelPassword === false ? 'Show Password' : 'Hide Password'}>
                        <i
                            className={labelPassword === false ? 'ki ki-hide icon-xl' : 'ki ki-eye icon-xl'}
                            onClick={togglePasswordVisiblity}
                        ></i>
                    </Tooltip >
                </div>
                <div className='form-group d-flex flex-wrap flex-center'>
                    {!isRequested ?
                        <button
                            id='kt_login_forgot_submit'
                            type='submit'
                            className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
                            disabled={formik.isSubmitting}
                        >
                            Submit
                        </button>
                        :
                        <Link to='/auth'>
                            <button
                                type='button'
                                id='kt_login_forgot_cancel'
                                className='btn btn-light-info font-weight-bold px-9 py-4 my-3 mx-4'
                            >
                                Sign In
                            </button>
                        </Link>
                    }
                </div>
            </form>
        </div>
    )
}

export default injectIntl(connect(null, auth.actions)(ForgotPasswordFormContainer))
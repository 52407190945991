import { createSlice } from '@reduxjs/toolkit'

const initialMenusState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    menuEntityDetails: undefined,
    menuAccessDetails: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const MenusReducers = createSlice({
    name: 'menus',
    initialState: initialMenusState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
                state.menuEntityDetails = undefined
                state.menuAccessDetails = undefined
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getMenuById
        menuFetched: (state, action) => {
            state.menuEntityDetails = undefined
            state.menuAccessDetails = undefined
            state.actionsLoading = false
            state.menuEntityDetails = action.payload.menuEntityDetails
            state.lastError = null
            state.internalError = null
        },
        // getMenuAccessByMenuId
        menuAccessFetched: (state, action) => {
            state.actionsLoading = false
            state.menuAccessDetails = action.payload.menuAccessDetails
            state.lastError = null
            state.internalError = null
        },
        // findMenus
        menusFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // createMenu
        menuCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entities.push(action.payload.menu)
            state.stayOpen = false
        },
        // updateMenu
        menuUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.menu.menu_id) {
                    return action.payload.menu
                }
                return entity
            })
        },
        // deleteMenu
        menuDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.entities = state.entities.filter(el => el.id !== action.payload.menu_id)
        }
    }
})

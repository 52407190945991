/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import objectPath from 'object-path'
import { NavLink, useLocation } from 'react-router-dom'

import * as app from '../../../../app/redux/app/app.reducers'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import 'bootstrap-daterangepicker/daterangepicker.css'

// import { QuickActions } from './components/QuickActions'
import { BreadCrumbs } from './components/BreadCrumbs'
import { getBreadcrumbsAndTitle, useSubheader } from '../../core/MetronicSubheader'
import { useHtmlClassService } from '../../core/MetronicLayout'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_helpers'

export function SubHeader() {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const subheader = useSubheader()
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        'subheader.mobile-toggle'
      ),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses(
        'subheader_container',
        true
      )
    }
  }, [uiService])
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname)
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname)
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title)
    // eslint-disable-next-line
  }, [location.pathname])
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader])
  const { sites } = useSelector(
    ({ app }) => ({
      sites: app.sites
    }),
    shallowEqual
  )

  const [state, setState] = useState({
    start: moment(),
    end: moment(),
  })
  const { start, end } = state
  const handleCallback = (start, end) => {
    setState({ start, end })
  }
  const label = '' + start === '' + end ? start.format('MMMM D, YYYY') : start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')

  const props = app.actions
  const dispatch = useDispatch()

  return (
    <div
      id='kt_subheader'
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className='flex-wrap mr-1 d-flex align-items-center'>
          {layoutProps.subheaderMobileToggle && (
            <button
              className='mr-4 burger-icon burger-icon-left d-inline-block d-lg-none'
              id='kt_subheader_mobile_toggle'
            >
              <span />
            </button>
          )}

          <div className='mr-5 d-flex align-items-baseline'>
            <h5 className='my-2 mr-5 text-dark font-weight-bold'>
              <>
                {subheader.title}
              </>
              {/*<small></small>*/}
            </h5>

          </div>


          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>

        {/* Toolbar */}
        {
          location.pathname === '/dashboard' ?
            <div className='d-flex align-items-center'>
              {/* <a href='#' className='btn btn-light btn-sm font-weight-bold'
                data-toggle='tooltip' title='Select dashboard daterange' data-placement='left'> */}
              {/* <span className='mr-2 text-muted font-weight-bold'>Today</span>
                <span className='text-primary font-weight-bold'>Aug 16</span> */}
              <DateRangePicker
                initialSettings={{
                  startDate: start.toDate(),
                  endDate: end.toDate(),
                  ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                      moment().subtract(1, 'days').toDate(),
                      moment().subtract(1, 'days').toDate(),
                    ],
                    'Last 7 Days': [
                      moment().subtract(6, 'days').toDate(),
                      moment().toDate(),
                    ],
                    'Last 30 Days': [
                      moment().subtract(29, 'days').toDate(),
                      moment().toDate(),
                    ],
                    'This Month': [
                      moment().startOf('month').toDate(),
                      moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                      moment().subtract(1, 'month').startOf('month').toDate(),
                      moment().subtract(1, 'month').endOf('month').toDate(),
                    ]
                  },
                }}
                // onCallback={handleCallback}
                onCallback={(start, end, label) => {
                  handleCallback(start, end, label)
                  // eslint-disable-next-line
                  // setFieldValue('dateRange', moment.utc(start).format('YYYY-MM-DD HH:mm:ss') + '[-]' + moment.utc(end).format('YYYY-MM-DD HH:mm:ss'))
                  // setFieldValue('endDate', moment.utc(end).format('YYYY-MM-DD HH:mm:ss'))
                  // handleSubmit()
                }}
              // value={values.dateRange}
              >
                <div
                  id='subheaderrange'
                  className='form-control'
                  style={{
                    background: '#fff',
                    cursor: 'pointer',
                    padding: '8px 13px',
                    border: '1px solid #ccc',
                    width: '100%',
                  }}
                >
                  <i className='fa fa-calendar'></i>&nbsp;
                  <span>{label}</span> <i className='fa fa-caret-down'></i>
                </div>
              </DateRangePicker>
              {/* </a> */}
              {/* <QuickActions /> */}
            </div>
            :
            <div className='d-flex align-items-center'>
              {
                sites !== undefined && sites.length > 1 ?
                  sites.map((site, index) => (

                    <OverlayTrigger
                      key={`ab${index}`}
                      placement='bottom'
                      overlay={<Tooltip id={site.site_id}>Sub Module {site.name}</Tooltip>}
                    >
                      <NavLink
                        key={`bc${index}`}
                        className='btn btn-default btn-sm font-weight-bold mr-2'
                        to={`/${site.path}`}
                        onClick={() => dispatch(props.selectSite(`${site.site_id}`))}
                      >
                        <span className='svg-icon svg-icon-default svg-icon-lg'>
                          <span className='svg-icon-primary svg-icon-2x'>
                            <SVG src={toAbsoluteUrl(`${site.icon}`)} />
                          </span>
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  )) :
                  ''
              }
            </div>
        }
      </div>
    </div>
  )
}

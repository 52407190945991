import { createSlice } from '@reduxjs/toolkit'

const initialParamsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    paramDetails: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const ParamsReducers = createSlice({
    name: 'parameters',
    initialState: initialParamsState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getParamById
        paramFetched: (state, action) => {
            state.actionsLoading = false
            state.paramDetails = action.payload.paramDetails
            state.lastError = null
            state.internalError = null
        },
        // findParams
        paramsFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // createParam
        paramCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entities.push(action.payload.parameter)
            state.stayOpen = false
        },
        // updateParam
        paramUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.parameter.parameter_id) {
                    return action.payload.parameter
                }
                return entity
            })
        },
        // deleteParam
        paramDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.entities = state.entities.filter(el => el.id !== action.payload.parameter_id)
        }
    }
})

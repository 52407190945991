/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from 'react'
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import SVG from 'react-inlinesvg'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../../core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { shallowEqual, useSelector } from 'react-redux'

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
}

export function UserNotificationsDropdown() {
  const [key, setKey] = useState('Alerts')
  const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg')

  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.notifications.layout') ===
        'offcanvas',
    }
  }, [uiService])

  // const { socket } = useSelector(
  //   ({ auth }) => ({
  //     socket: auth.socket
  //   }),
  //   shallowEqual
  // )

  // const [notifications, setNotifications] = useState([])
  // const [open, setOpen] = useState(false)

  // useEffect(() => {
  //   socket && socket.on("getNotification", (data) => {
  //     setNotifications((prev) => [...prev, data])
  //   })

  //   // eslint-disable-next-line
  // }, [socket])

  // const displayNotification = ({ type }) => {
  //   let action;

  //   if (type === 1) {
  //     action = "alert"
  //   } else if (type === 2) {
  //     action = "event"
  //   } else {
  //     action = "log"
  //   }
  //   return <>
  //     <span className='pulse-ring'></span>
  //     <span className='pulse-ring' />
  //   </>

  // }

  // const handleRead = () => {
  //   setNotifications([])
  //   setOpen(false)
  // }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className='topbar-item'>
          <div
            className='mr-1 btn btn-icon btn-clean btn-lg pulse pulse-primary'
            id='kt_quick_notifications_toggle'
          >
            <span className='svg-icon svg-icon-xl svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
            </span>
            <span className='pulse-ring'></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop='down' alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id='kt_quick_notifications_toggle'
          >
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='user-notification-tooltip'>
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className='mr-1 btn btn-icon btn-clean btn-lg pulse pulse-primary'
                id='kt_quick_notifications_toggle'
              >
                <span className='svg-icon svg-icon-xl svg-icon-primary'>
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')}
                  />
                </span>
                <span className='pulse-ring'></span>
                <span className='pulse-ring' />
                {/* {notifications.map((n) => displayNotification(n))} */}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className='p-0 m-0 dropdown-menu dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg'>
            <form>
              {/** Head */}
              <div
                className='pt-12 d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top'
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className='d-flex flex-center rounded-top'>
                  <span className='text-white'>User Notifications</span>
                  <span className='ml-2 btn btn-text btn-success btn-sm font-weight-bold btn-font-md'>
                    {/* {notifications.length} new */}
                    23 new
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as='ul'
                    className='px-8 mt-3 nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success'
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className='nav-item' as='li' >
                      {/* <Nav.Item className='nav-item' as='li' onClick={() => setOpen(!open)}> */}
                      <Nav.Link
                        eventKey='Alerts'
                        className={`nav-link show ${key === 'Alerts' ? 'active' : ''
                          }`}
                      >
                        Alerts
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li'>
                      {/* <Nav.Item as='li' onClick={() => setOpen(!open)}> */}
                      <Nav.Link
                        eventKey='Events'
                        className={`nav-link show ${key === 'Events' ? 'active' : ''
                          }`}
                      >
                        Events
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li'>
                      {/* <Nav.Item as='li' onClick={() => setOpen(!open)}> */}
                      <Nav.Link
                        eventKey='Logs'
                        className={`nav-link show ${key === 'Logs' ? 'active' : ''
                          }`}
                      >
                        Logs
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className='tab-content'>
                    <Tab.Pane eventKey='Alerts' className='p-8'>
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className='scroll pr-7 mr-n7'
                        style={{ maxHeight: '300px', position: 'relative' }}
                      >
                        <div className='mb-6 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-primary'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Home/Library.svg'
                                )}
                                className='svg-icon-lg svg-icon-primary'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                        <div className='mb-6 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-warning'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Communication/Write.svg'
                                )}
                                className='svg-icon-lg svg-icon-warning'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark-75 text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                        <div className='mb-6 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-success'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Communication/Group-chat.svg'
                                )}
                                className='svg-icon-lg svg-icon-success'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                        <div className='mb-6 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-danger'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/General/Attachment2.svg'
                                )}
                                className='svg-icon-lg svg-icon-danger'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-info'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/General/Attachment2.svg'
                                )}
                                className='svg-icon-lg svg-icon-info'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-info'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Communication/Mail-notification.svg'
                                )}
                                className='svg-icon-lg svg-icon-info'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <div className='mr-5 symbol symbol-40 symbol-light-info'>
                            <span className='symbol-label'>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Design/Bucket.svg'
                                )}
                                className='svg-icon-lg svg-icon-info'
                              ></SVG>
                            </span>
                          </div>
                          <div className='d-flex flex-column font-weight-bold'>
                            <a
                              href='#'
                              className='mb-1 text-dark text-hover-primary font-size-lg'
                            >
                              Briviba SaaS
                            </a>
                            <span className='text-muted'>
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey='Events'
                      id='topbar_notifications_events'
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className='my-4 navi navi-hover scroll'
                        style={{ maxHeight: '300px', position: 'relative' }}
                      >
                        <a href='#' className='navi-item'>
                          {/* <a href='#' className='navi-item' onClick={handleRead}> */}
                          <div className='navi-link'>
                            <div className='mr-2 navi-icon'>
                              <i className='flaticon-download text-success'></i>
                            </div>
                            <div className='navi-text'>
                              <div className='font-weight-bold'>
                                New export file has been received
                              </div>
                              <div className='text-muted'>23 hrs ago</div>
                            </div>
                          </div>
                        </a>
                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane eventKey='Logs' id='topbar_notifications_logs'>
                      <div className='text-center d-flex flex-center text-muted min-h-200px'>
                        All caught up!
                        <br />
                        No new notifications.
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function ListShortTerm({ className }) {
    return (
        <>
            <div className={`card card-custom ${className}`}>
                {/* Header */}
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-success">Current Project</h3>
                </div>
                {/* Body */}
                <div className="card-body pt-0">
                    <div className='table-responsive' style={{ maxHeight: '500px' }}>
                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Phone.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Orins Shop Mobile Applications
                                    </a>
                                    <span className="text-primary font-weight-bold">
                                        On Progress - Starting develop Master Data Orins Shop Module
                                    </span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Refactor Orins Shop base domain to new Orins Shop Mobile Applications with new feature:
                                <br />
                                - approval purchase: [asm, qc, accounting, and oli])
                                <br />
                                - buyer can monitor purchase proggress
                                <br />
                                - integrated with ESB purchase orders
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/icons/Devices/Laptop.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Migrate Soft Data ESB to ERP Orins Corp
                                    </a>
                                    <span className="text-primary font-weight-bold">
                                        On Progress - Starting develop Master Data ESB Module
                                    </span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Migrate ESB Data by excel to ERP Orins Corp
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

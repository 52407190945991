import { createSlice } from '@reduxjs/toolkit'

const initialOutletState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    outletForView: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const OutletReducers = createSlice({
    name: 'moka_outlets',
    initialState: initialOutletState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getOutlet
        outletFetched: (state, action) => {
            state.actionsLoading = false
            state.outletForView = action.payload.outletForView
            state.lastError = null
            state.internalError = null
        },
        // findOutlets
        outletsFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        }
    }
})

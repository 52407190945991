import React, { useEffect, useState } from "react";
import useVirtual from "react-cool-virtual";
import axios from "axios";

const api = {
    APP_INFO_URL: `${process.env.REACT_APP_APP_API_URL}/app-info`,
}

function toLocaleDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Aor', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nov', 'Des']

    const today = new Date(date)
    const month = months[today.getMonth()]

    return today.getDate() + ' ' + month + ' ' + today.getFullYear()
}

const loadData = async ({ loadIndex }, setAppInfos, isItemLoadedArr) => {
    isItemLoadedArr[loadIndex] = true;

    try {
        const { data: appInfos } = await axios.get(`${api.APP_INFO_URL}/for-panel-v2/${loadIndex + 1}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } });

        if (appInfos.data !== null) {
            setAppInfos((prevAppInfos) => {
                const nextAppInfos = [...prevAppInfos];

                appInfos.data.forEach((appInfo) => {
                    nextAppInfos[appInfo.id - 1] = appInfo;
                });

                return nextAppInfos;
            });
        } else {
            isItemLoadedArr[loadIndex] = false;
        }
    } catch (err) {
        isItemLoadedArr[loadIndex] = false;
        loadData({ loadIndex }, setAppInfos, isItemLoadedArr);
    }
};

const DataAppInfo = ({ total }) => {
    const cAppInfos = total;
    const batchAppInfos = 5;

    const [isItemLoadedArr, setIItemLoadedArr] = useState([]);
    const [appInfos, setAppInfos] = useState([]);
    const { outerRef, innerRef, items } = useVirtual({
        itemCount: cAppInfos,
        itemSize: 122,
        loadMoreCount: batchAppInfos,
        isItemLoaded: (loadIndex) => isItemLoadedArr[loadIndex],
        loadMore: (e) => loadData(e, setAppInfos, isItemLoadedArr)
    });

    useEffect(() => {
        if (isItemLoadedArr.length > 0) {
            const resetIsLoaderArr = isItemLoadedArr.map((dt) => false)
            setIItemLoadedArr(resetIsLoaderArr)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div ref={outerRef} style={{ width: "100%", height: "720px", overflow: "auto" }}>
            <div ref={innerRef}>
                {items.map(({ index, measureRef }) => (
                    <div
                        key={appInfos[index]?.id || `fb - ${index}`}
                        className="timeline-item align-items-start"
                        style={{ paddingTop: "16px", width: "100%" }}
                        ref={measureRef}
                    >
                        {appInfos[index]
                            ?
                            <>
                                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                    {/* eslint-disable-next-line */}
                                    <a
                                        href="#"
                                        className="text-dark text-hover-primary mb-1 font-size-lg"
                                    >
                                        {toLocaleDate(appInfos[index].info_date)}
                                    </a>
                                </div>

                                <div className="timeline-badge">
                                    <i className="fa fa-genderless text-success icon-xl"></i>
                                </div>

                                <div className="d-flex flex-column font-weight-bold">
                                    {/* eslint-disable-next-line */}
                                    <a
                                        href="#"
                                        className="text-muted text-hover-primary mb-1 font-size-lg"
                                    >
                                        {appInfos[index].type_data}
                                    </a>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: appInfos[index].info_detail.replaceAll('<p>', '').replaceAll('</p>', '<br/>') }}
                                    />
                                </div>
                            </>
                            :
                            "⏳ Loading..."}
                    </div>
                ))}
            </div>
        </div >
    );
};

export default DataAppInfo;

import { createSlice } from '@reduxjs/toolkit'

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  userForEdit: undefined,
  lastError: null,
  internalError: null,
  stayOpen: true,
  batchResult: []
}
export const callTypes = {
  list: 'list',
  action: 'action'
}

export const UsersReducers = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    setOpen: (state) => {
      state.stayOpen = true
    },
    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
      state.stayOpen = true
    },
    catchBadRequest: (state, action) => {
      state.lastError = `${action.payload.error}`
      state.listLoading = false
      state.actionsLoading = false
      state.stayOpen = true
    },
    catchInternalError: (state, action) => {
      state.internalError = `${action.payload.error}`
      state.listLoading = false
      state.actionsLoading = false
      state.stayOpen = true
    },
    startCall: (state, action) => {
      state.lastError = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
      state.stayOpen = true
    },
    // getUserById
    userFetched: (state, action) => {
      state.actionsLoading = false
      state.userForEdit = action.payload.userForEdit
      state.lastError = null
      state.internalError = null
    },
    // findUsers
    usersFetched: (state, action) => {
      const { totalCount, entities } = action.payload
      state.listLoading = false
      state.lastError = null
      state.entities = entities
      state.totalCount = totalCount
      state.stayOpen = true
    },
    // createUser
    userCreated: (state, action) => {
      state.actionsLoading = false
      state.lastError = null
      state.entities.push(action.payload.user)
      state.stayOpen = false
    },
    // updateUser
    userUpdated: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.stayOpen = false
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.user.user_id) {
          return action.payload.user
        }
        return entity
      })
    },
    // deleteUser
    userDeleted: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.entities = state.entities.filter(el => el.id !== action.payload.user_id)
    },
    // deleteUsers
    usersDeleted: (state, action) => {
      state.lastError = null
      state.actionsLoading = false
      state.stayOpen = false
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      )
    },
    // usersUpdateState
    usersStatusUpdated: (state) => {
      state.actionsLoading = true
      state.lastError = null
      state.stayOpen = true
    },
    // usersRoleState
    usersRoleUpdated: (state) => {
      state.actionsLoading = true
      state.lastError = null
      state.stayOpen = true
    },
    // usersDeleteState
    usersDeletedState: (state) => {
      state.actionsLoading = true
      state.lastError = null
      state.stayOpen = true
    },
    // setBatchResultStatus
    setBatchResult: (state, action) => {
      state.batchResult.push(action.payload.data)
    },
    // batchActionsFinished
    batchActionsFinished: (state) => {
      state.actionsLoading = false
      state.lastError = null
      state.stayOpen = true
    },
    // setInitBatch
    reInitBatch: (state) => {
      state.actionsLoading = false
      state.internalError = null
      state.lastError = null
      state.stayOpen = true
      state.batchResult = []
    }
  }
})

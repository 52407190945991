import { createSlice } from '@reduxjs/toolkit'

const initialLogsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    totalActivitiesCount: 0,
    totalActivitiesByUserIDCount: 0,
    entities: null,
    activityEntities: null,
    activityNextEntities: null,
    logsForView: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const LogsReducers = createSlice({
    name: 'logs',
    initialState: initialLogsState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getLog
        logFetched: (state, action) => {
            state.actionsLoading = false
            state.logsForView = action.payload.logsForView
            state.lastError = null
            state.internalError = null
        },
        // findLogs
        logsFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // countActivitiesByUserID
        countActivityByUserIDFetched: (state, action) => {
            const { totalActivitiesByUserIDCount } = action.payload
            state.listLoading = false
            state.lastError = null
            state.totalActivitiesByUserIDCount = totalActivitiesByUserIDCount
            state.stayOpen = true
        },
        // findActivities
        activityFetched: (state, action) => {
            const { activityEntities, totalActivitiesCount } = action.payload
            state.listLoading = false
            state.lastError = null
            state.activityEntities = activityEntities
            state.totalActivitiesCount = totalActivitiesCount
            state.stayOpen = true
        },
        // findActivitiesNext
        activityNextFetched: (state, action) => {
            const { activityNextEntities, totalActivitiesCount } = action.payload
            state.listLoading = false
            state.lastError = null
            state.activityNextEntities = activityNextEntities
            state.totalActivitiesCount = state.totalActivitiesCount + totalActivitiesCount
            state.stayOpen = true
        }
    }
})

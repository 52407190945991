import React, { useEffect, useState } from "react";
import useVirtual from "react-cool-virtual";
import axios from "axios";

const api = {
    APP_LOGS_URL: `${process.env.REACT_APP_APP_API_URL}/log`,
}

const convertUTCToLocalTime = (dateString) => {
    let date = new Date(dateString);
    var dates = date.toLocaleDateString("en-GB", {
        "year": "numeric",
        "month": "long",
        "day": "numeric",
        "hour": "2-digit",
        "minute": "2-digit"
    })

    return dates = dates === "1 January 1, 07:07" ? "-" : dates + ' WIB'
}

const loadData = async ({ loadIndex }, setActivities, isItemLoadedArr) => {
    isItemLoadedArr[loadIndex] = true;

    try {
        const { data: activities } = await axios.get(`${api.APP_LOGS_URL}/activity-next-v2/${loadIndex + 1}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } });

        if (activities.data !== null) {
            setActivities((prevActivities) => {
                const nextActivities = [...prevActivities];

                activities.data.forEach((comment) => {
                    nextActivities[comment.id - 1] = comment;
                });

                return nextActivities;
            });
        } else {
            isItemLoadedArr[loadIndex] = false;
        }
    } catch (err) {
        isItemLoadedArr[loadIndex] = false;
        loadData({ loadIndex }, setActivities, isItemLoadedArr);
    }
};

const DataLastActivity = ({ total }) => {
    const cActivities = total;
    const batchActivities = 5;

    const [isItemLoadedArr, setIItemLoadedArr] = useState([]);
    const [activities, setActivities] = useState([]);
    const { outerRef, innerRef, items } = useVirtual({
        itemCount: cActivities,
        itemSize: 122,
        loadMoreCount: batchActivities,
        isItemLoaded: (loadIndex) => isItemLoadedArr[loadIndex],
        loadMore: (e) => loadData(e, setActivities, isItemLoadedArr)
    });

    useEffect(() => {
        if (isItemLoadedArr.length > 0) {
            const resetIsLoaderArr = isItemLoadedArr.map((dt) => false)
            setIItemLoadedArr(resetIsLoaderArr)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div ref={outerRef} style={{ width: "100%", height: "500px", overflow: "auto" }}>
            <div ref={innerRef}>
                {items.map(({ index, measureRef }) => (
                    <div
                        key={activities[index]?.id || `fb-${index}`}
                        className={`item ${index % 2 ? "dark" : ""}`}
                        style={{ padding: "16px", width: "100%" }}
                        ref={measureRef}
                    >
                        <table className="table table-hover table-vertical-center" >
                            <thead></thead>
                            <tbody>
                                {activities[index]
                                    ?
                                    <>
                                        <td style={{ minWidth: "250px" }}>
                                            {convertUTCToLocalTime(activities[index].datetime)}
                                        </td>
                                        <td style={{ minWidth: "250px" }}>
                                            <span>
                                                {activities[index].module}
                                            </span>
                                        </td>
                                        <td style={{ minWidth: "250px" }}>
                                            {activities[index].menu}
                                        </td>
                                        <td style={{ minWidth: "100px" }}>
                                            {activities[index].activity}
                                        </td>
                                    </>
                                    :
                                    "⏳ Loading..."}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default DataLastActivity;

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function ListCoreModule({ className }) {
    return (
        <>
            <div className={`card card-custom ${className}`}>
                {/* Header */}
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">Core Module</h3>
                </div>
                {/* Body */}
                <div className="card-body pt-0">
                    <div className='table-responsive' style={{ maxHeight: '500px' }}>
                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/misc/016-websocket.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Web Socket
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                        Container Service
                                    </span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Implement web socket for moduling socket push event handler on container service
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/misc/017-database.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Multi Database
                                    </a>
                                    <span className="text-muted font-weight-bold">Database Service</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Implement multi database source for performance purpose
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center text-success"
                                            src={toAbsoluteUrl("/media/svg/misc/018-reactjs.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-success text-hover-success font-size-lg mb-1"
                                    >
                                        Implement Infinity Scroll
                                    </a>
                                    <span className="text-success font-weight-bold">Web Service</span>
                                </div>
                            </div>

                            <p className="text-success m-0 pt-5 font-weight-normal">
                                Implemented
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/misc/018-reactjs.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Implement Push Notifications
                                    </a>
                                    <span className="text-muted font-weight-bold">Web Service</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Implement push notifications using web socket to trigger new event or job push notifications
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/misc/018-reactjs.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Implement Messaging
                                    </a>
                                    <span className="text-muted font-weight-bold">Web Service</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Implement messaging using web socket between users to improve user experience
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/misc/018-reactjs.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Implement Profile To Do List
                                    </a>
                                    <span className="text-muted font-weight-bold">Web Service</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Implemet to do list app to create user to do activity or work list
                            </p>
                        </div>

                        <div className="mb-10">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                        <SVG
                                            className="h-50 align-self-center"
                                            src={toAbsoluteUrl("/media/svg/misc/018-reactjs.svg")}
                                        ></SVG>
                                    </span>
                                </div>

                                <div className="d-flex flex-column flex-grow-1">
                                    <a
                                        href="#"
                                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                    >
                                        Implement Team Project Management List
                                    </a>
                                    <span className="text-muted font-weight-bold">Web Service</span>
                                </div>
                            </div>

                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Implement project management app to monitor and manage user and team project
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
import toast from 'react-hot-toast'

import { getUserByToken, getIPAddr, updateUserWithAvatar, updateUser, updatePassword } from './auth.api'

export const actionTypes = {
  Login: '[Login] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[User Loaded] Auth API',
  UpdateUserWithAvatarRequested: '[Request Update User With Avatar] Action',
  UpdateUserRequested: '[Request Update User] Action',
  UpdatePasswordRequested: '[Request Update Password] Action',
  UserUpdated: '[User Updated] Action',
  PasswordUpdated: '[Password Updated] Action',
  SocketLoaded: '[Socket Loaded] App API',
  IPRequested: '[Request IP] Action',
  IPLoaded: '[IP Loaded] Auth API',
  Logout: '[Logout] Action',
}

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  ipAddr: undefined,
  socket: undefined
}

export const authReducer = persistReducer(
  { storage, key: 'root', whitelist: ['authToken', 'user', 'ipAddr'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload
        return { authToken, user: undefined }
      }
      case actionTypes.Logout: {
        return initialAuthState
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user }
      }
      case actionTypes.UserUpdated: {
        const { user } = action.payload
        return { ...state, user }
      }
      case actionTypes.PasswordUpdated: {
        return { ...state }
      }
      case actionTypes.IPLoaded: {
        const { ipAddr } = action.payload
        return { ...state, ipAddr }
      }
      case actionTypes.SocketLoaded: {
        const { socket } = action.payload
        return { ...state, socket }
      }
      default:
        return state
    }
  }
)

export const actions = {
  authFromToken: (authToken) => ({
    type: actionTypes.Login,
    payload: { authToken }
  }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user }
  }),
  requestUpdateUserWithAvatar: (payload) => ({
    type: actionTypes.UpdateUserWithAvatarRequested,
    payload
  }),
  requestUpdateUser: (user) => ({
    type: actionTypes.UpdateUserRequested,
    payload: { user }
  }),
  fulfillUpdateUser: (user) => ({
    type: actionTypes.UserUpdated,
    payload: { user }
  }),
  requestUpdatePassword: (payload) => ({
    type: actionTypes.UpdatePasswordRequested,
    payload
  }),
  passwordUpdated: () => ({
    type: actionTypes.PasswordUpdated
  }),
  setSocket: (socket) => ({
    type: actionTypes.SocketLoaded,
    payload: { socket }
  }),
  requestIP: () => ({
    type: actionTypes.IPRequested,
  }),
  fulfillIP: (ipAddr) => ({
    type: actionTypes.IPLoaded,
    payload: { ipAddr }
  }),
  logout: () => ({
    type: actionTypes.Logout
  }),
}

export function* authSagas() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const response = yield getUserByToken()
    const user = response.data.data

    yield put(actions.fulfillUser(user))
  })

  yield takeLatest(actionTypes.IPRequested, function* ipRequested() {
    const response = yield getIPAddr()
    const ipAddr = response.data.IPv4

    yield put(actions.fulfillIP(ipAddr))
  })

  yield takeLatest(actionTypes.UpdateUserWithAvatarRequested, function* requestUpdateUserWithAvatar(payload) {
    try {
      const response = yield updateUserWithAvatar(payload.payload)
      if (response.status === 201) {
        yield put(actions.fulfillUpdateUser(response.data.data))
      }
    } catch (error) {
      toast.error(error)
      console.error(error)
    }
  })

  yield takeLatest(actionTypes.UpdateUserRequested, function* requestUpdateUser(payload) {
    try {
      const response = yield updateUser(payload.payload.user)
      if (response.status === 201) {
        yield put(actions.fulfillUpdateUser(response.data.data))
      }
    } catch (error) {
      toast.error(error)
      console.error(error)
    }
  })

  yield takeLatest(actionTypes.UpdatePasswordRequested, function* requestUpdatePassword(payload) {
    try {
      const response = yield updatePassword(payload.payload)
      if (response.data.meta.code === 200) {
        yield put(actions.passwordUpdated())
        toast.success('Password Updated')
      } else {
        toast.error(response.data.meta.message)
      }
    } catch (error) {
      toast.error(error)
      console.error(error)
    }
  })
}
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

import * as actions from '../../../../app/redux/app_infos/app_infos.actions'

import { ModalProgressBar } from "../../controls"
import DataAppInfo from "./data/DataAppInfo"

function toLocaleDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Aor', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nov', 'Des']

    const today = new Date(date)
    const month = months[today.getMonth()]

    return today.getDate() + ' ' + month + ' ' + today.getFullYear()
}

export function AppInfo({ className, dashboard }) {
    const dispatch = useDispatch()
    const { currentState } = useSelector(
        (state) => ({
            currentState: state.app_infos
        }),
        shallowEqual
    )

    const { entitiesForPanel, totalAppInfosCount, listLoading } = currentState
    useEffect(() => {
        dispatch(actions.fetchCountAppInfos())
        dispatch(actions.fetchAppInfosForPanel(10))
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {
                totalAppInfosCount > 0 &&
                <div className={`card card-custom ${className}`}>
                    {listLoading && <ModalProgressBar />}
                    <div className="card-body pt-4">
                        <div className='table-responsive' style={dashboard === true ? {} : { maxWidth: '7600px', maxHeight: '1200px' }}>
                            <div className="timeline timeline-6 mt-3">
                                {
                                    totalAppInfosCount > 10 ?
                                        <DataAppInfo total={totalAppInfosCount} />
                                        :
                                        <>
                                            {
                                                entitiesForPanel.map((data, i) => (
                                                    <div className="timeline-item align-items-start" key={i} >
                                                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                                            <a
                                                                href="#"
                                                                className="text-dark text-hover-primary mb-1 font-size-lg"
                                                            >
                                                                {toLocaleDate(data.info_date)}
                                                            </a>
                                                        </div>

                                                        <div className="timeline-badge">
                                                            <i className="fa fa-genderless text-success icon-xl"></i>
                                                        </div>

                                                        <div className="d-flex flex-column font-weight-bold">
                                                            <a
                                                                href="#"
                                                                className="text-muted text-hover-primary mb-1 font-size-lg"
                                                            >
                                                                {data.type_data}
                                                            </a>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: data.info_detail.replaceAll('<p>', '').replaceAll('</p>', '<br/>') }}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

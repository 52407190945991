import toast from 'react-hot-toast'

import * as api from './logs.api'
import { LogsReducers, callTypes } from './logs.reducers'

const { actions } = LogsReducers

export const fetchLogs = (queryParams) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return api
        .findLogs(queryParams)
        .then(response => {
            const res = response.data
            dispatch(actions.logsFetched({ totalCount: res.meta.totalCount, entities: res.data }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message, {
                    duration: 4000,
                })

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const fetchCountActivitiesByUserID = () => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))

    try {
        const response = await api
            .fetchCountActivitiesByUserID()
        const res = response.data.data
        dispatch(actions.countActivityByUserIDFetched({ totalActivitiesByUserIDCount: res }))
    } catch (err) {
        if (err.response != null && err.response.data.meta != null) {
            const code = err.response.status
            const message = err.response.data.meta.message
            err.clientMessage = message
            toast.error(message, {
                duration: 4000,
            })

            if (code === 400) {
                dispatch(actions.catchBadRequest({ error: message }))
            } else {
                dispatch(actions.catchError({ error: message, callType: callTypes.action }))
            }
        } else {
            dispatch(actions.catchInternalError({ error: err }))
        }
    }
}

export const fetchActivities = (limit) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return api
        .findActivities(limit)
        .then(response => {
            const res = response.data
            dispatch(actions.activityFetched({ activityEntities: res.data, totalActivitiesCount: res.data.length }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message, {
                    duration: 4000,
                })

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const fetchActivitiesNext = (start, limit) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))

    return api
        .fetchActivitiesNext(start, limit)
        .then(response => {
            const res = response.data.data
            dispatch(actions.activityNextFetched({ activityNextEntities: res, totalActivitiesCount: res.length }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message, {
                    duration: 4000,
                })

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const fetchLog = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.logFetched({ logsForView: undefined }))
    }

    dispatch(actions.startCall({ callType: callTypes.action }))
    return api.getLog(id)
        .then(response => {
            const log = response.data.data
            dispatch(actions.logFetched({ logsForView: log }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message)

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

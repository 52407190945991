import toast from 'react-hot-toast'

import * as api from './app_infos.api'
import { AppInfoReducers, callTypes } from './app_infos.reducers'

const { actions } = AppInfoReducers

export const fetchAppInfos = queryParams => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return api
        .findAppInfos(queryParams)
        .then(response => {
            const res = response.data
            dispatch(actions.appInfosFetched({ totalCount: res.meta.totalCount, entities: res.data }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message, {
                    duration: 4000,
                })

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const fetchCountAppInfos = () => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))

    try {
        const response = await api
            .fetchCountAppInfos()
        const res = response.data.data
        dispatch(actions.countAppInfosFetched({ totalAppInfosCount: res }))
    } catch (err) {
        if (err.response != null && err.response.data.meta != null) {
            const code = err.response.status
            const message = err.response.data.meta.message
            err.clientMessage = message
            toast.error(message, {
                duration: 4000,
            })

            if (code === 400) {
                dispatch(actions.catchBadRequest({ error: message }))
            } else {
                dispatch(actions.catchError({ error: message, callType: callTypes.action }))
            }
        } else {
            dispatch(actions.catchInternalError({ error: err }))
        }
    }
}

export const fetchAppInfosForPanel = limit => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }))
    return api
        .findAppInfosForPanel(limit)
        .then(response => {
            const res = response.data
            dispatch(actions.appInfosForPanelFetched({ entitiesForPanel: res.data }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message, {
                    duration: 4000,
                })

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const fetchAppInfo = id => dispatch => {
    if (!id) {
        return dispatch(actions.appInfoFetched({ appInfoByID: undefined }))
    }

    dispatch(actions.startCall({ callType: callTypes.action }))
    return api.getAppInfoById(id)
        .then(response => {
            const appInfo = response.data.data
            dispatch(actions.appInfoFetched({ appInfoByID: appInfo }))
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message)

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const createAppInfo = (appInfoForCreation, queryParams) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }))
    return api
        .createAppInfo(appInfoForCreation)
        .then(response => {
            const appInfo = response.data.data
            dispatch(actions.appInfoCreated({ appInfo }))
            dispatch(actions.setOpen())
            dispatch(fetchAppInfos(queryParams))
            toast.success(response.data.meta.message)
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message)

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const updateAppInfo = (appInfo, queryParams) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }))

    return api
        .updateAppInfo(appInfo)
        .then(response => {
            const appInfo = response.data.data
            dispatch(actions.appInfoUpdated({ appInfo }))
            dispatch(actions.setOpen())
            dispatch(fetchAppInfos(queryParams))
            toast.success(response.data.meta.message)
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message)

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const updateAppInfosStatus = (ids, status, queryParams, ipAddr, menuId) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }))
    var cIds = ids.length

    for (let i = 0; i < ids.length; i++) {
        try {
            const response = await api.updateStatusForAppInfos(ids[i], status, ipAddr, menuId)
            const data = response.data.data
            dispatch(actions.appInfosStatusUpdated())
            dispatch(actions.setBatchResult({ data }))
            if (i === (cIds - 1)) {
                toast.success(response.data.meta.message)
                dispatch(actions.batchActionsFinished())
            }
        } catch (err) {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                const data = err.response.data.meta.errors
                err.clientMessage = message
                toast.error(message)
                dispatch(actions.setBatchResult({ data }))

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        }
    }

    setTimeout(() => {
        dispatch(fetchAppInfos(queryParams))
    }, 1e3)
}

export const deleteAppInfo = (info_id, ipAddr, menuId) => async dispatch => {
    const appInfo = {
        info_id: undefined,
        menu_id: undefined,
        ip_address: undefined
    }

    dispatch(actions.startCall({ callType: callTypes.action }))
    return api
        .deleteAppInfo(info_id, appInfo, ipAddr, menuId)
        .then(() => {
            dispatch(actions.appInfoDeleted({ info_id }))
            dispatch(actions.setOpen())
            toast.success('App Info Deleted')
        })
        .catch(err => {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                err.clientMessage = message
                toast.error(message)

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        })
}

export const deleteAppInfos = (appInfos, queryParams, ipAddr, menuId) => async dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }))
    var cIds = appInfos.length

    for (let i = 0; i < appInfos.length; i++) {
        try {
            let info_id = appInfos[i]['info_id']
            const response = await api.deleteAppInfos(info_id, ipAddr, menuId)
            const data = response.data.data
            dispatch(actions.appInfosDeletedState())
            dispatch(actions.setBatchResult({ data }))
            if (i === (cIds - 1)) {
                toast.success(response.data.meta.message)
                dispatch(actions.batchActionsFinished())
            }
        } catch (err) {
            if (err.response != null && err.response.data.meta != null) {
                const code = err.response.status
                const message = err.response.data.meta.message
                const data = err.response.data.meta.errors
                err.clientMessage = message
                toast.error(message)
                dispatch(actions.setBatchResult({ data }))

                if (code === 400) {
                    dispatch(actions.catchBadRequest({ error: message }))
                } else {
                    dispatch(actions.catchError({ error: message, callType: callTypes.action }))
                }
            } else {
                dispatch(actions.catchInternalError({ error: err }))
            }
        }
    }

    setTimeout(() => {
        dispatch(fetchAppInfos(queryParams))
    }, 1e3)
}

export const setInitBatch = () => async dispatch => {
    dispatch(actions.reInitBatch())
}

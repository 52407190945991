/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import * as actions from '../../../../app/redux/logs/logs.actions'

import { ModalProgressBar } from '../../controls'
import DataLastActivity from './data/DataLastActivity'

const convertUTCToLocalTime = (dateString) => {
    let date = new Date(dateString);
    var dates = date.toLocaleDateString("en-GB", {
        "year": "numeric",
        "month": "long",
        "day": "numeric",
        "hour": "2-digit",
        "minute": "2-digit"
    })

    return dates = dates === "1 January 1, 07:07" ? "-" : dates + ' WIB'
}

export function LastActivity({ className }) {
    const dispatch = useDispatch()
    const { currentState } = useSelector(
        (state) => ({
            currentState: state.logs
        }),
        shallowEqual
    )
    const { activityEntities, listLoading, totalActivitiesCount, totalActivitiesByUserIDCount } = currentState
    useEffect(() => {
        dispatch(actions.fetchCountActivitiesByUserID())
        dispatch(actions.fetchActivities(10))
        // eslint-disable-next-line
    }, [])


    return (
        <>
            {
                totalActivitiesCount > 0 &&
                <div className={`card card-custom ${className}`}>
                    {listLoading && <ModalProgressBar />}
                    {/* Head */}
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className={`card-label font-weight-bolder ${listLoading === true ? 'text-success' : 'text-dark'}`}>
                                My Activity
                            </span>
                            <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                {
                                    totalActivitiesByUserIDCount > 10 ?
                                        totalActivitiesByUserIDCount
                                        :
                                        totalActivitiesCount
                                } activities loaded
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            {listLoading && (
                                <div className='d-flex align-items-center'>
                                    <div className='mr-2 text-muted'>Loading...</div>
                                    <div className='spinner spinner-primary mr-10'></div>
                                </div>
                            )}
                        </div>
                        <div className="table-responsive mt-5 mb-3">
                            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                <thead>
                                    <tr className="text-left text-uppercase">
                                        <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Datetime</span></th>
                                        <th style={{ minWidth: "250px" }}>Module</th>
                                        <th style={{ minWidth: "250px" }}>Menu</th>
                                        <th style={{ minWidth: "100px" }}>Activity</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    {/* Body */}
                    <div className="card-body pt-2 pb-0">
                        {
                            totalActivitiesByUserIDCount > 10 ?
                                <DataLastActivity total={totalActivitiesByUserIDCount} />
                                :
                                <table className="table table-hover table-vertical-center" >
                                    <thead></thead>
                                    <tbody>
                                        {activityEntities.map((data, i) => (
                                            <tr key={i} className="text-left text-uppercase" >
                                                <td className="pl-7 py-5" style={{ minWidth: "250px" }}>
                                                    {convertUTCToLocalTime(data.datetime)}
                                                </td>
                                                <td style={{ minWidth: "250px" }}>
                                                    <span>
                                                        {data.module}
                                                    </span>
                                                </td>
                                                <td style={{ minWidth: "250px" }}>
                                                    {data.menu}
                                                </td>
                                                <td style={{ minWidth: "100px" }}>
                                                    {data.activity}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                        }
                    </div>
                </div>
            }
        </>
    )
}

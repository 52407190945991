/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import AuthFormContainer from '../../components/auth/AuthForm'
import ActivationFormContainer from '../../components/auth/ActivationForm'
import ForgotPasswordFormContainer from '../../components/auth/ForgotPasswordForm'

import '../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import { ContentRoute } from '../../../_metronic/layout'

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white p-2 p-lg-10"
          id="kt_login"
        >
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/orins/erp-orins-corp-big.png")})`,
              backgroundPosition: 'center',
            }}
          >
          </div>

          <div
            className="d-flex flex-column flex-row-fluid position-relative p-7"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`,
            }}
          >
            <div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-0">
              <Switch>
                <ContentRoute path='/auth/login' component={AuthFormContainer} />
                <ContentRoute
                  path='/auth/activation/:token'
                  component={ActivationFormContainer}
                />
                <ContentRoute
                  path='/auth/forgot-password'
                  component={ForgotPasswordFormContainer}
                />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'

import * as app from '../redux/app/app.reducers'

import { ERPOrinsDashboard } from '../components/dashboard/ERPOrinsDashboard'
import { MokaDashboard } from '../components/dashboard/MokaDashboard'
import { AppInfo } from '../../_metronic/_partials/widgets/orins-app-info/AppInfo'
import { ListCoreModule, ListShortTerm, ListMidTerm } from '../../_metronic/_partials/widgets'

export function HomePage() {
  const props = app.actions
  const dispatch = useDispatch()

  useEffect(() => {
    // set default state to undefined
    dispatch(props.selectModule(undefined))
    dispatch(props.fetchSite(undefined))
    dispatch(props.selectSite(undefined))
    dispatch(props.fetchMenus(undefined))
    dispatch(props.selectMenu(undefined))
    dispatch(props.fulfillCurrentMenu(undefined))
    dispatch(props.fulfillCurrentMenuAccess(undefined))

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Tabs defaultActiveKey="app_info" id="uncontrolled-tab-example">
            <Tab eventKey="app_info" title='App Info'>
              <div className='card'>
                <AppInfo className="card-stretch gutter-b" dashboard={true} />
              </div>
            </Tab>
            <Tab eventKey="it_dev_plan" title='Orins Corp Plan'>
              <div className={`card card-custom bg-gray-200`}>
                <div className="row">
                  <div className="col-lg-4">
                    <ListCoreModule className="card-stretch gutter-b"></ListCoreModule>
                  </div>
                  <div className="col-lg-4">
                    <ListShortTerm className="card-stretch gutter-b"></ListShortTerm>
                  </div>
                  <div className="col-lg-4">
                    <ListMidTerm className="card-stretch gutter-b"></ListMidTerm>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="sales" title='Sales Dashboard'>
              <div className={`card card-custom bg-gray-100`}>
                <div className="row">
                  <div className="col-lg-6">
                    <ERPOrinsDashboard className="card-stretch gutter-b" />
                  </div>
                  <div className="col-lg-6">
                    <MokaDashboard className="card-stretch gutter-b" />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

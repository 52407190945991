/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export function BreadCrumbs({ items }) {
  const location = useLocation()
  if (!items || !items.length) {
    return ''
  }

  return (
    <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2'>
      <li className='breadcrumb-item'>
        {
          location.pathname !== "/dashboard" &&
          <Link to='/dashboard'>
            <i className='flaticon2-shelter text-muted icon-1x' />
          </Link>
        }
      </li>
      {
        location.pathname !== "/dashboard" &&

        items.map((item, index) => (
          <li key={`bc${index}`} className='breadcrumb-item'>
            <Link className='text-muted' to={{ pathname: item.pathname }}>
              {item.title}
            </Link>
          </li>
        ))
      }
    </ul>
  )
}

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import AuthInit from './app/redux/auth/auth.init'
import { Routes } from './app/routing/routes'

import { MaterialThemeProvider, LayoutSplashScreen } from './_metronic/layout'
import { I18nProvider } from './_metronic/i18n'

export default function App({ store, persistor, basename }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <MaterialThemeProvider>
              <I18nProvider>
                <AuthInit>
                  <Routes />
                </AuthInit>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  )
}

import axios from 'axios'

export const api = {
  MODULES_URL: `${process.env.REACT_APP_APP_API_URL}/module`,
  ROLE_URL: `${process.env.REACT_APP_APP_API_URL}/role-access`,
  SITES_URL: `${process.env.REACT_APP_APP_API_URL}/site`,
  MENUS_URL: `${process.env.REACT_APP_APP_API_URL}/menu`,
  APP_URL: `${process.env.REACT_APP_APP_API_URL}/app`,
  APP_DATA_URL: `${process.env.REACT_APP_APP_API_URL}/app-data`,
  REPORT_URL: `${process.env.REACT_APP_REPORT_API_URL}/divisi-it/daily`,
}

// Fetching Modules
export function getModulesByToken() {
  return axios.get(api.MODULES_URL, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fetching Sites by module id
export function getSitesByModuleId(id) {
  return axios.get(`${api.SITES_URL}/module-id/${id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fetching Module Menus by module id and site id
export function getMenusByModuleIdSiteId(module_id, site_id) {
  return axios.get(`${api.MENUS_URL}/module/site/${module_id}/${site_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fetching Menu by menu id
export function getMenusByMenuId(menu_id) {
  return axios.get(`${api.MENUS_URL}/${menu_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fetching Get menu access by token and menu id
export function getCurrentMenuAccessByTokenAndMenuID(role_id, menu_id) {
  return axios.get(`${api.ROLE_URL}/user-menu-access/${role_id}/${menu_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething User Roles for Filter
export function fetchRolesForFilter(module_id) {
  return axios.get(`${api.APP_URL}/filter/user-roles/${module_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething Users for Filter
export function fetchUsersForFilter(module_id) {
  return axios.get(`${api.APP_URL}/filter/users-log/${module_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething Users for Filter
export function fetchUsersForDailyFilter() {
  return axios.get(`${api.APP_URL}/filter/daily-activity`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething Modules for Filter
export function fetchModulesForFilter(module_id) {
  return axios.get(`${api.APP_URL}/filter/modules-log/${module_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething Menus for Filter
export function fetchMenusForFilter(module_id) {
  return axios.get(`${api.APP_URL}/filter/menus-log/${module_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething Params for Filter
export function fetchParamsForFilter(module_id) {
  return axios.get(`${api.APP_URL}/filter/parameters/${module_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// app_data
// Fething Task Categories for Filter
export function fetchTaskCategoriesForFilter(task_category_id) {
  task_category_id = task_category_id === undefined ? null : task_category_id
  return axios.get(`${api.APP_URL}/filter/task-categories/${task_category_id} `, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething Task Items for Filter
export function fetchTaskItemsForFilter(task_category_id, task_item_id) {
  task_category_id = task_category_id === undefined ? null : task_category_id
  task_item_id = task_item_id === undefined ? null : task_item_id
  return axios.get(`${api.APP_URL}/filter/task-items/${task_category_id}/${task_item_id}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Task Items for Filter at input daily activity
export function fetchTaskItemsForInputDailyActivity() {
  return axios.get(`${api.APP_URL}/filter/task-items-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Task Items All Status for Filter Data on Daily Activity Delete Dialog
export function fetchTaskItemsAllStatus() {
  return axios.get(`${api.APP_URL}/filter/task-items-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Create New App Data Child From Select
export function cretaeAppDataChildFromSelect(app_data) {
  return axios.post(`${api.APP_DATA_URL}/store-child-from-select`, { app_data }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething App Name for Input Select on App Info
export function fetchAppNamesForInputAppInfo() {
  return axios.get(`${api.APP_URL}/filter/app-name-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething App Name All Status 
export function fetchAppNamesAllStatus() {
  return axios.get(`${api.APP_URL}/filter/app-name-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Type Info for Input Select on App Info
export function fetchTypeInfosForInputAppInfo() {
  return axios.get(`${api.APP_URL}/filter/type-info-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Type Info All Status 
export function fetchTypeInfosAllStatus() {
  return axios.get(`${api.APP_URL}/filter/type-info-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Troubleshoot Status for Filter
export function fetchTroubleshootStatusForFilter() {
  return axios.get(`${api.APP_URL}/filter/troubleshoot-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY} ` } })
}

// Fething User Division for Input Select on Office Troubleshoot Data
export function fetchUserDivisionsForInputOfficeTroubleshootData() {
  return axios.get(`${api.APP_URL}/filter/user-division-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething User Division All Status 
export function fetchUserDivisionsAllStatus() {
  return axios.get(`${api.APP_URL}/filter/user-division-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething User for Input Select on Office Troubleshoot Data
export function fetchUsersForInputOfficeTroubleshootData() {
  return axios.get(`${api.APP_URL}/filter/user-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething User  All Status 
export function fetchUsersAllStatus() {
  return axios.get(`${api.APP_URL}/filter/user-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Outlet Branch for Input Select on Outlet Troubleshoot Data
export function fetchOutletBranchsForInputOutletTroubleshootData() {
  return axios.get(`${api.APP_URL}/filter/outlet-branch-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Outlet Branch All Status 
export function fetchOutletBranchsAllStatus() {
  return axios.get(`${api.APP_URL}/filter/outlet-branch-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Outlet PIC for Input Select on Outlet Troubleshoot Data
export function fetchOutletPICForInputOutletTroubleshootData() {
  return axios.get(`${api.APP_URL}/filter/outlet-pic-for-input`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

// Fething Outlet PIC  All Status 
export function fetchOutletPICsAllStatus() {
  return axios.get(`${api.APP_URL}/filter/outlet-pic-all-status`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}
// app_data
import React, { useMemo } from 'react'
import { useHtmlClassService } from '../../core/MetronicLayout'

export function Footer() {
  const today = new Date().getFullYear()
  const uiService = useHtmlClassService()

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true)
    }
  }, [uiService])

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id='kt_footer'
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className='order-2 text-dark order-md-1'>
          <span className='mr-2 text-muted font-weight-bold'>{today.toString()}</span> &copy; &nbsp;
          {/* eslint-disable-next-line */}
          <a
            href='#'
            rel='noopener noreferrer'
            className='text-dark-75 text-hover-primary'
          >
            OMI IT Development
          </a>
        </div>
        <div className='order-1 nav nav-dark order-md-2'>
          <a
            href='https://erp.orins.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='pl-0 pr-3 nav-link'
          >
            ERP Orins
          </a>
          <a
            href='https://backoffice.mokapos.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='px-3 pr-0 nav-link'
          >
            MOKA Orins
          </a>
        </div>
      </div>
    </div>
  )
}

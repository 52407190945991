import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
// import { io } from 'socket.io-client'

// import * as auth from '../redux/auth/auth.reducers'

import PrivateRoutes from './PrivateRoutes'
import { AuthPage } from '../pages/auth/auth.page'
import LogoutPage from '../pages/auth/auth.logout.page'
import ErrorPage from '../pages/error/ErrorPage'

import { Layout } from '../../_metronic/layout'

export function Routes() {
  // const props = auth.actions
  // const dispatch = useDispatch()
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      user: auth.user
    }),
    shallowEqual
  )

  // const [socket, setSocket] = useState(null)
  // useEffect(() => {
  //   // user !== undefined && setSocket(io("http://localhost:5000"))
  //   setSocket(io("http://localhost:5000"))
  // }, [])

  // useEffect(() => {
  //   socket && user && socket.emit("onlineUser", user.username)
  //   socket && user && socket.emit("broadcastNotification2", {
  //     senderName: user.username,
  //     // receiverName: user.username,
  //     type: 2
  //   })
  //   dispatch(props.setSocket(socket))

  //   // eslint-disable-next-line
  // }, [socket, user])

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorPage} />
      <Route path="/logout" component={LogoutPage} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        // <Layout socket={socket}>
        <Layout >
          <PrivateRoutes />
        </Layout>
      )
      }
    </Switch >
  )
}
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import { Tooltip } from '@material-ui/core'

import * as auth from '../../redux/auth/auth.reducers'
import { authFromLogin } from '../../redux/auth/auth.api'

const initialValues = {
  email: '',
  password: '',
  token: '',
}

function AuthFormContainer(props) {
  const inputRef = useRef()
  const { intl } = props
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState(null)
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(5, 'Minimum 5 character')
      .max(50, 'Maximum 50 character')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(8, 'Minimum 8 character')
      .max(50, 'Maximum 50 character')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  })

  const handleToken = (token) => {
    setCaptcha(token)
  }

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      setTimeout(() => {
        if (captcha === null) {
          disableLoading()
          setSubmitting(false)
          setStatus('Please verified captcha first!')
          setTimeout(function () {
            setStatus(null)
          }, 2e3)
          return false
        }

        authFromLogin(values.email, values.password)
          .then((response) => {
            disableLoading()

            const { data, meta } = response.data
            if (meta.code === 200) {
              var authToken = data
              document.getElementById('init_info').style = 'opacity: 0'
              document.getElementById('success_info').style = 'display: block'
              setTimeout(function () {
                props.authFromToken(authToken)
                props.requestIP()
              }, 1e3)
            } else {
              setStatus(meta.message)
              setTimeout(function () {
                setStatus(null)
                document.getElementById('init_info').style = 'opacity: 1'
              }, 4e3)
            }
          })
          .catch((err) => {
            console.log(err)
            setStatus(
              intl.formatMessage({
                id: 'AUTH.ERROR.API',
              })
            )
            setTimeout(function () {
              setStatus(null)
            }, 2e3)
          })
          .finally(() => {
            disableLoading()
            setSubmitting(false)
          })
      }, 1e1)
    },
  })

  const [passwordShown, setPasswordShown] = useState(false)
  const [labelPassword, setLabelPassword] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
    setLabelPassword(labelPassword ? false : true)
  }

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='mb-10 text-center mb-lg-10'>
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          <div className="flex-column-fluid d-flex flex-column justify-content-end">
            <h3 className="font-size-h1 mb-5 text-dark custom-background">
              Welcome to ERP ORINS Corp
            </h3>
            <p className="font-weight-lighter text-dark opacity-80 custom-background">
              Improved application from previous version with new features & responsive page.
            </p>
          </div>
        </div>
        <h3 className='font-size-h1 mt-5'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        {formik.status ? (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>
              {formik.status}
            </div>
          </div>
        ) : (
          <div id='init_info' className='mb-10 alert alert-custom alert-light-info alert-dismissible' style={{ display: 'block' }}>
            <div className='alert-text font-weight-bold '>
              Enter your <strong>email</strong> and
              <strong> password{' '}</strong> to continue.
            </div>
          </div>
        )}

        <div id='success_info' className='mb-10 alert alert-custom alert-light-success alert-dismissible' style={{ display: 'none' }}>
          <div className='alert-text font-weight-bold '>
            User Authenticated.
          </div>
        </div>

        <div className='form-group fv-plugins-icon-container mb-3'>
          <input
            placeholder='Email'
            type='email'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name='email'
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container mb-3'>
          <input
            ref={inputRef}
            placeholder='Password'
            type={passwordShown ? 'text' : 'password'}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name='password'
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
          <Tooltip title={labelPassword === false ? 'Show Password' : 'Hide Password'}>
            <i
              className={labelPassword === false ? 'ki ki-hide icon-xl mb-3' : 'ki ki-eye icon-xl mb-3'}
              onClick={togglePasswordVisiblity}
            ></i>
          </Tooltip >
        </div>
        <div className='form-group fv-plugins-icon-container mb-3'>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={handleToken}
          // onExpire={handleExpire}
          />
        </div>
        <div className='flex-wrap form-group d-flex justify-content-between align-items-center'>
          <Link
            to='/auth/forgot-password'
            id='kt_login_forgot'
          >
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
          </Link>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(AuthFormContainer))

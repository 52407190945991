/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import objectPath from 'object-path'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import * as app from '../../../../../app/redux/app/app.reducers'

import { useHtmlClassService } from '../../../core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth)
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, 'extras.user.dropdown.style') ===
        'light',
    }
  }, [uiService])

  const props = app.actions
  const dispatch = useDispatch()

  return (
    <Dropdown drop='down' alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id='dropdown-toggle-user-profile'
      >
        <div
          className={
            'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
          }
        >
          <span className='mr-1 text-muted font-weight-bold font-size-base d-none d-md-inline'>
            Hi,
          </span>{' '}
          <span className='mr-3 text-dark-50 font-weight-bolder font-size-base d-none d-md-inline'>
            {user.username.toUpperCase()}
          </span>
          <span className='symbol symbol-35 symbol-light-success'>
            <span className='symbol-label font-size-h5 font-weight-bold'>
              {user.username_prefix}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className='p-8 d-flex align-items-center rounded-top'>
                <div className='flex-shrink-0 mr-3 symbol symbol-md bg-light-primary'>
                  <img src={toAbsoluteUrl('/media/users/300_21.jpg')} alt='' />
                </div>
                <div className='m-0 mr-3 text-dark flex-grow-1 font-size-h5'>
                  {user.username.toUpperCase()}
                </div>
                <span className='label label-light-success label-lg font-weight-bold label-inline'>
                  3 messages
                </span>
              </div>
              <div className='separator separator-solid'></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className='flex-wrap p-8 d-flex align-items-center justify-content-between bgi-size-cover bgi-no-repeat rounded-top'
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  '/media/misc/bg-1.jpg'
                )})`,
              }}
            >
              <div className='mr-3 symbol bg-white-o-15'>
                <span className='symbol-label text-success font-weight-bold font-size-h4'>
                  {user.username_prefix}
                </span>
                {/*<img alt='Pic' className='hidden' src={user.pic} />*/}
              </div>
              <div className='m-0 mr-3 text-white flex-grow-1 font-size-h5'>
                {user.fullname}
              </div>
              <span className='label label-success label-lg font-weight-bold label-inline'>
                3 messages
              </span>
            </div>
          )}
        </>

        <div className='pt-5 navi navi-spacer-x-0'>
          <Link
            to='/user-profile'
            className='px-8 cursor-pointer navi-item'
            onClick={() => dispatch(props.selectModule('26'))}
          >
            <div className='navi-link'>
              <div className='mr-2 navi-icon'>
                <i className='flaticon2-calendar-3 text-success' />
              </div>
              <div className='navi-text'>
                <div className='cursor-pointer font-weight-bold'>
                  My Profile
                </div>
                <div className='text-muted'>
                  Account settings and more
                </div>
              </div>
            </div>
          </Link>

          <a className='px-8 navi-item'>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Coming soon...</Tooltip>}
            >
              <div className='navi-link'>
                <div className='mr-2 navi-icon'>
                  <i className='flaticon2-mail text-warning'></i>
                </div>
                <div className='navi-text'>
                  <div className='font-weight-bold'>My Messages</div>
                  <div className='text-muted'>Inbox and tasks</div>
                </div>
              </div>
            </OverlayTrigger>
          </a>

          <a className='px-8 navi-item'>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Coming soon...</Tooltip>}
            >
              <div className='navi-link'>
                <div className='mr-2 navi-icon'>
                  <i className='flaticon2-rocket-1 text-danger'></i>
                </div>
                <div className='navi-text'>
                  <div className='font-weight-bold'>My Activities</div>
                  <div className='text-muted'>Logs and notifications</div>
                </div>
              </div>
            </OverlayTrigger>
          </a>

          <a className='px-8 navi-item'>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Coming soon...</Tooltip>}
            >
              <div className='navi-link'>
                <div className='mr-2 navi-icon'>
                  <i className='flaticon2-hourglass text-primary'></i>
                </div>
                <div className='navi-text'>
                  <div className='font-weight-bold'>My Tasks</div>
                  <div className='text-muted'>latest tasks and projects</div>
                </div>
              </div>
            </OverlayTrigger>
          </a>
          <div className='mt-3 navi-separator'></div>

          <div className='px-8 py-5 modal-footer'>
            <Link
              to='/logout'
              className='btn btn-light font-weight-bold'
            >
              <i className='fas fa-power-off icon-md'></i>
              &nbsp;
              Logout
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

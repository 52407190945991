/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch, connect } from 'react-redux'

import * as app from '../../../../../app/redux/app/app.reducers'
import { getMenusByModuleIdSiteId, getSitesByModuleId } from '../../../../../app/redux/app/app.api'

import { ModuleMenu } from './module-menu/ModuleMenu'

import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'

function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const props = app.actions
  const dispatch = useDispatch()
  const didRequest = useRef(false)
  const { modules, moduleId, siteId } = useSelector(
    ({ app }) => ({
      modules: app.modules,
      moduleId: app.moduleId,
      siteId: app.siteId
    }),
    shallowEqual
  )

  useEffect(() => {
    // fetch sites
    const requestSite = async () => {
      try {
        if (!didRequest.current) {
          const response = await getSitesByModuleId(moduleId)
          const sites = response.data.data

          dispatch(props.fetchSite(sites))
          dispatch(props.selectSite(sites[0].site_id))
        }
      } catch (error) {
        console.error(error)
      }
      return () => (didRequest.current = true)
    }

    (location.pathname === '/general' || location.pathname === '/user-profile') && requestSite()
    // eslint-disable-next-line
  }, [moduleId])

  useEffect(() => {
    // fetch menus
    const requestMenu = async () => {
      try {
        if (!didRequest.current && siteId) {
          const response = await getMenusByModuleIdSiteId(moduleId, siteId)
          const menus = response.data.data

          dispatch(props.fetchMenus(menus))
        }
      } catch (error) {
        console.error(error)
      }
      return () => (didRequest.current = true)
    }

    (location.pathname === '/general' || location.pathname === '/general/settings/users/app_users' || location.pathname === '/user-profile' || location.pathname === '/user-profile/profile-overview') && requestMenu()

    // eslint-disable-next-line
  }, [siteId])

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/* begin::Module section */}
        <li className="menu-section ">
          <h4 className="menu-text">Module</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: Module section */}

        {/* begin::Module */}
        {
          modules !== undefined && modules.length > 0 ?
            modules.map((module) => (
              <li
                key={module.module_id}
                className={`menu-item ${getMenuItemActive(`${module.path}`, false)}`}
                aria-haspopup='true'
                onClick={() => dispatch(props.selectModule(`${module.module_id}`))}
              >
                <NavLink className='menu-link' to={`/${module.path}`}>
                  <span className='svg-icon svg-icon-xl menu-icon'>
                    <SVG
                      src={toAbsoluteUrl(
                        `${module.icon}`
                      )}
                    />
                  </span>
                  <span className='menu-text'>{module.name}</span>
                </NavLink>
              </li>
            )) :
            ''
        }
        {/* end::Module */}

        {
          location.pathname !== "/dashboard" ?
            <ModuleMenu />
            :
            ''
        }
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}

export default connect(null, app.actions)(AsideMenuList)
import axios from 'axios'

export const api = {
    APP_INFO_URL: `${process.env.REACT_APP_APP_API_URL}/app-info`,
}

export function createAppInfo(appInfo) {
    return axios.post(api.APP_INFO_URL, { appInfo }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function updateAppInfo(appInfo) {
    return axios.put(`${api.APP_INFO_URL}/${appInfo.info_id}`, { appInfo }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function getAppInfoById(infoId) {
    return axios.get(`${api.APP_INFO_URL}/${infoId}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function findAppInfos(queryParams) {
    return axios.post(`${api.APP_INFO_URL}/find`, { queryParams }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function fetchCountAppInfos() {
    return axios.get(`${api.APP_INFO_URL}/count-for-panel`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function findAppInfosForPanel(limit) {
    return axios.get(`${api.APP_INFO_URL}/for-panel/${limit}`, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function updateStatusForAppInfos(ids, status, ipAddr, menuId) {
    return axios.post(`${api.APP_INFO_URL}/batchUpdatesStatus`, {
        ids,
        status,
        ip_address: ipAddr,
        menu_id: menuId
    }, {
        headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` }
    })
}

export function deleteAppInfo(info_id, appInfo, ipAddr, menuId) {
    appInfo.info_id = info_id
    appInfo.menu_id = menuId
    appInfo.ip_address = ipAddr
    return axios.patch(`${api.APP_INFO_URL}/${info_id}`, { appInfo }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

export function deleteAppInfos(ids, ipAddr, menuId) {
    return axios.post(`${api.APP_INFO_URL}/deleteInfos`, {
        ids,
        ip_address: ipAddr,
        menu_id: menuId
    }, { headers: { 'X-API-KEY': `${process.env.REACT_APP_APP_API_KEY}` } })
}

import { createSlice } from '@reduxjs/toolkit'

const initialMokaState = {
    listLoading: false,
    actionsLoading: false,
    accountForView: undefined,
    lastError: null,
    internalError: null
}
export const callTypes = {
    action: 'action'
}

export const MokaReducers = createSlice({
    name: 'moka_account',
    initialState: initialMokaState,
    reducers: {
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            state.actionsLoading = false
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.actionsLoading = false
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.actionsLoading = false
        },
        startCall: (state) => {
            state.lastError = null
            state.actionsLoading = true
        },
        // getAccountFromMokaAPI
        accountFetched: (state, action) => {
            state.actionsLoading = false
            state.accountForView = action.payload.accountForView
            state.lastError = null
            state.internalError = null
        }
    }
})

// Mixed
// export { MixedWidget1 } from "./mixed/MixedWidget1";
// export { MixedWidget4 } from "./mixed/MixedWidget4";
// export { MixedWidget6 } from "./mixed/MixedWidget6";
// export { MixedWidget10 } from "./mixed/MixedWidget10";
// export { MixedWidget11 } from "./mixed/MixedWidget11";
// export { MixedWidget12 } from "./mixed/MixedWidget12";
// export { MixedWidget14 } from "./mixed/MixedWidget14";

// Lists
export { ListsWidget1 } from "./lists/ListsWidget1";
// export { ListsWidget3 } from "./lists/ListsWidget3";
export { ListsWidget4 } from "./lists/ListsWidget4";
// export { ListsWidget8 } from "./lists/ListsWidget8";
// export { ListsWidget9 } from "./lists/ListsWidget9";
export { ListsWidget10 } from "./lists/ListsWidget10";
// export { ListsWidget11 } from "./lists/ListsWidget11";
// export { ListsWidget14 } from "./lists/ListsWidget14";

// Stats
// export { StatsWidget10 } from "./stats/StatsWidget10";
// export { StatsWidget11 } from "./stats/StatsWidget11";
// export { StatsWidget12 } from "./stats/StatsWidget12";

// Base tables
// export { BaseTablesWidget1 } from "./base-tables/BaseTablesWidget1";
// export { BaseTablesWidget2 } from "./base-tables/BaseTablesWidget2";
// export { BaseTablesWidget6 } from "./base-tables/BaseTablesWidget6";

// Advance tables
// export { AdvanceTablesWidget1 } from "./advance-tables/AdvanceTablesWidget1";
// export { AdvanceTablesWidget2 } from "./advance-tables/AdvanceTablesWidget2";
// export { AdvanceTablesWidget4 } from "./advance-tables/AdvanceTablesWidget4";
// export { AdvanceTablesWidget7 } from "./advance-tables/AdvanceTablesWidget7";
// export { AdvanceTablesWidget9 } from "./advance-tables/AdvanceTablesWidget9";

// Tiles
// export { TilesWidget1 } from "./tiles/TilesWidget1";
// export { TilesWidget3 } from "./tiles/TilesWidget3";
// export { TilesWidget10 } from "./tiles/TilesWidget10";
// export { TilesWidget11 } from "./tiles/TilesWidget11";
// export { TilesWidget12 } from "./tiles/TilesWidget12";
// export { TilesWidget13 } from "./tiles/TilesWidget13";
// export { TilesWidget14 } from "./tiles/TilesWidget14";

// Orins
export { LastActivity } from './orins-last-activity/LastActivity'
export { ListCoreModule } from './orins-it-development-plan/ListCoreModule'
export { ListShortTerm } from './orins-it-development-plan/ListShortTerm'
export { ListMidTerm } from './orins-it-development-plan/ListMidTerm'

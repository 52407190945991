import { createSlice } from '@reduxjs/toolkit'

const initialSitesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    sitesEntityDetails: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true,
    batchResult: []
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const SitesReducers = createSlice({
    name: 'sites',
    initialState: initialSitesState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // getSiteById
        siteFetched: (state, action) => {
            state.actionsLoading = false
            state.sitesEntityDetails = action.payload.sitesEntityDetails
            state.lastError = null
            state.internalError = null
        },
        // findSites
        sitesFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // createSite
        siteCreated: (state, action) => {
            state.actionsLoading = false
            state.lastError = null
            state.entities.push(action.payload.site)
            state.stayOpen = false
        },
        // updateSite
        siteUpdated: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.site.site_id) {
                    return action.payload.site
                }
                return entity
            })
        },
        // deleteSite
        siteDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.entities = state.entities.filter(el => el.id !== action.payload.site_id)
        },
        // deleteSites
        sitesDeleted: (state, action) => {
            state.lastError = null
            state.actionsLoading = false
            state.stayOpen = false
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            )
        },
        // sitesUpdateState
        sitesStatusUpdated: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // sitesDeleteState
        sitesDeletedState: (state) => {
            state.actionsLoading = true
            state.lastError = null
            state.stayOpen = true
        },
        // setBatchResultStatus
        setBatchResult: (state, action) => {
            state.batchResult.push(action.payload.data)
        },
        // batchActionsFinished
        batchActionsFinished: (state) => {
            state.actionsLoading = false
            state.lastError = null
            state.stayOpen = true
        },
        // setInitBatch
        reInitBatch: (state) => {
            state.actionsLoading = false
            state.internalError = null
            state.lastError = null
            state.stayOpen = true
            state.batchResult = []
        }
    }
})

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ErrorPage1 } from './ErrorPage1'

export default function ErrorPage() {
  return (
    <Switch>
      <Redirect from='/error' exact={true} to='/error/error' />
      <Route path='/error/error' component={ErrorPage1} />
    </Switch>
  )
}

import axios from 'axios'

export const api = {
  TOKEN_AUTH: `${process.env.REACT_APP_USER_API_URL}/auth`,
  LOGIN_URL: `${process.env.REACT_APP_USER_API_URL}/auth`,
  USER_URL: `${process.env.REACT_APP_USER_API_URL}/users`,
  ACTIVATION_URL: `${process.env.REACT_APP_USER_API_URL}/users/activation`,
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(api.TOKEN_AUTH)
}

export function getIPAddr() {
  var newAxios = axios.create()
  delete newAxios.defaults.headers.common['Authorization']

  return newAxios.get('https://geolocation-db.com/json/')
}

export function authFromLogin(email, password) {
  return axios.post(api.LOGIN_URL, { email, password })
}

export function requestActivation(password, token) {
  return axios.post(api.ACTIVATION_URL, { password, token })
}

export function requestResendPassword(email, password) {
  return axios.post(`${api.USER_URL}/resend-password`, { email, password })
}

export function updateUserWithAvatar(payload) {
  const formData = new FormData();
  formData.append('file', payload.tmp_path)
  formData.append('fullname', payload.fullname)
  formData.append('email', payload.email)
  formData.append('menu_id', payload.menu_id)
  formData.append('ip_address', payload.ip_address)
  return axios.post(`${api.USER_URL}/avatar-update`, formData, { headers: { 'content-type': 'multipart/form-data' } })
}

export function updateUser(user) {
  return axios.post(`${api.USER_URL}/personal-information-update`, user)
}

export function updatePassword(payload) {
  return axios.post(`${api.USER_URL}/update-password`, payload)
}

import { createSlice } from '@reduxjs/toolkit'

const initialESBMasterProductState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entityByID: undefined,
    lastError: null,
    internalError: null,
    stayOpen: true,
    initialFilter: {
        filter: {
            category_id_source: "",
            sub_category_id_source: "",
            type: "",
            status: "",
            search: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    },
    categoriesForFilter: null,
    subCategoriesForFilter: null,
    typesForFilter: null,
}
export const callTypes = {
    list: 'list',
    action: 'action'
}

export const ESBMasterProductReducers = createSlice({
    name: 'ebs_master_product',
    initialState: initialESBMasterProductState,
    reducers: {
        setOpen: (state) => {
            state.stayOpen = true
        },
        catchError: (state, action) => {
            state.lastError = `${action.type}: ${action.payload.error}`
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false
            } else {
                state.actionsLoading = false
            }
            state.stayOpen = true
        },
        catchBadRequest: (state, action) => {
            state.lastError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        catchInternalError: (state, action) => {
            state.internalError = `${action.payload.error}`
            state.listLoading = false
            state.actionsLoading = false
            state.stayOpen = true
        },
        startCall: (state, action) => {
            state.lastError = null
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true
            } else {
                state.actionsLoading = true
            }
            state.stayOpen = true
        },
        // fetchCategoriesForFilter
        CategoriesForFilterFetched: (state, action) => {
            state.listLoading = false
            state.categoriesForFilter = action.payload.categoriesForFilter
            state.lastError = null
            state.internalError = null
            state.stayOpen = true
        },
        // fetchSubCategoriesForFilter
        SubCategoriesForFilterFetched: (state, action) => {
            state.listLoading = false
            state.subCategoriesForFilter = action.payload.subCategoriesForFilter
            state.lastError = null
            state.internalError = null
            state.stayOpen = true
        },
        // fetchTypesForFilter
        TypesForFilterFetched: (state, action) => {
            state.listLoading = false
            state.typesForFilter = action.payload.typesForFilter
            state.lastError = null
            state.internalError = null
            state.stayOpen = true
        },
        // getESBMasterProduct
        ESBMasterProductFetched: (state, action) => {
            state.actionsLoading = false
            state.entityByID = action.payload.entityByID
            state.lastError = null
            state.internalError = null
        },
        // fetchESBMasterProducts
        ESBMasterProductsFetched: (state, action) => {
            const { totalCount, entities } = action.payload
            state.listLoading = false
            state.lastError = null
            state.entities = entities
            state.totalCount = totalCount
            state.stayOpen = true
        },
        // set initialFilter
        reInitFilter: (state, action) => {
            state.initialFilter = action.payload.updateFilter
        }
    }
})
